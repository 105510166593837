import { styled } from "~/ui/style/stitches.config";
import { ReactElement, memo, useContext, useEffect, useRef, useState } from "react";
import {
  useOthers,
  useSelf,
  useUpdateMyPresence,
} from "../state/liveblocks.config";
import { CURSOR_COLORS } from "../ui/room/Cursors";
import { useSnapshot } from "valtio";
import Daily, { DailyCall } from "@daily-co/daily-js";
import {
  DailyProvider,
  useLocalParticipant,
  useMediaTrack,
  useDevices,
  useParticipant,
  useParticipantIds,
  useVideoTrack,
} from "@daily-co/daily-react-hooks";
import { AnimatePresence, motion } from "framer-motion";
import { AVControls, MuteIndicator } from "~/ui/menus/AVControls";
import { getRoom, updateRoom } from "~/api/roomApi";
import { useLocation } from "wouter";
import { MAX_ROOM_ATTENDEES } from "~/utils/consts";
import { localState } from "~/state/state";
import { sendAnalyticsEvent } from "~/utils/analytics";
import { AuthContext } from "~/utils/useAuth";

export const AV = memo(function AV({
  isAVLoaded,
  setIsAVLoaded,
  roomId,
}: {
  isAVLoaded: boolean;
  setIsAVLoaded: Function;
  roomId: string;
}) {
  const numOthers = useOthers((others) => others.length);
  const otherConnectionIds = useOthers((others) =>
    others.map((other) => other.connectionId)
  );
  const myColor = useSelf((self) => self.presence.color);
  const fallbackColor = "hsla(0, 0%, 68%, 1)";
  const connectionId = useSelf((me) => me.connectionId);
  const colorOffset: number = roomId.charCodeAt(0);
  const [_, setLocation] = useLocation();
  const [callObject, setCallObject] = useState<DailyCall | null>(null);
  const [isJoined, setIsJoined] = useState(false);
  const [userId, setUserId] = useState("");
  const [displayName, setdisplayName] = useState("");
  const [userName, setUserName] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [previousNumUsers, setNumUsers] = useState(1);
  const updateMyPresence = useUpdateMyPresence();
  const { user, updateUser } = useContext(AuthContext)

  const callObjectRef = useRef<DailyCall | null>(null);

  const setCameraOffAndMicMuted = (isCameraOff: boolean, isMuted: boolean) => {
    if (!callObject) return;
    const newSetting = {
      setVideo: !isCameraOff,
      setAudio: !isMuted,
    };
    callObject.updateParticipant("local", newSetting);
    updateUser({ isMuted, isCameraOff })
  };

  useEffect(() => {
    if (otherConnectionIds.length === 0) {
      updateMyPresence({
        color: CURSOR_COLORS[colorOffset % CURSOR_COLORS.length],
      });
      return;
    }
    if (isJoined && callObject) {
      const sortedConnectionIds = [...otherConnectionIds];
      sortedConnectionIds.push(connectionId);
      sortedConnectionIds.sort((a, b) => a - b);
      const newColor =
        CURSOR_COLORS[
          (colorOffset + sortedConnectionIds.indexOf(connectionId)) %
            CURSOR_COLORS.length
        ];
      updateMyPresence({
        color: newColor,
      });
    }
  }, [isJoined, otherConnectionIds.length]);

  useEffect(() => {
    (async () => {
      if (user?.uuid && callObject) {
        if (isJoined) {
          callObject.setUserData({
            connectionId: connectionId,
            userName: user?.username ? user?.username : "",
            displayName: user?.name ? user?.name : "",
            profilePic: user.animalPic
              ? user.animalPic
              : user?.profilePic
              ? user?.profilePic
              : "",
            userId: user?.uuid ? user?.uuid : "",
            color: myColor,
          });
        }

        if (user.profilePic) {
          setProfilePic(
            user.animalPic ? user.animalPic : user.profilePic
          );
          setUserId(user.uuid);
          setdisplayName(user.name);
          setUserName(user.username);
        }
      }
    })();
  }, [user, callObject, isJoined, myColor]);

  useEffect(() => {
    (async () => {
      const numPresent = callObject?.participantCounts().present;
      if (numPresent) {
        if (numPresent > previousNumUsers) {
          sendAnalyticsEvent("room_gained_user", {
            room_num_users: numPresent,
            room_name: roomId,
          });
        } else if (numPresent < previousNumUsers) {
          sendAnalyticsEvent("room_lost_user", {
            room_num_users: numPresent,
            room_name: roomId,
          });
        }
        setNumUsers(numPresent);
        await updateRoom(roomId, numPresent);
      }
    })();
  }, [callObject?.participantCounts().present]);

  useEffect(() => {
    if (!callObject || !user) return;
    (async () => {
      const room = await getRoom(roomId);
      setIsAVLoaded(true);
      localState.isConnecting = false;

      if (numOthers > 0) {
        if (!room) {
          localState.isConnecting = false;
          setLocation("/room404");
          return;
        }
        if (
          !isJoined &&
          room.numParticipants &&
          room.numParticipants > MAX_ROOM_ATTENDEES - 1
        ) {
          //setRoomFull
          localState.isConnecting = false;
          return;
        }
        const { url } = room;
        if (!isJoined) {
          await callObject
            .join({ url: url })
            .then(() => {
              setCameraOffAndMicMuted(user.isCameraOff, user.isMuted);
              setIsJoined(true);
            });
        }
      } else {
        if (!isJoined) {
          await callObject.startCamera();
          setCameraOffAndMicMuted(user.isCameraOff, user.isMuted);
        }
      }
    })();
  }, [numOthers, callObject, isJoined]);

  useEffect(() => {
    (async () => {
      if (callObject) return;
      // Type: https://docs.daily.co/reference/rest-api/rooms
      const call = Daily.createCallObject({
        userData: {
          connectionId: connectionId,
        },
      });

      // Get and join the room
      const room = await getRoom(roomId);
      if (!room) setLocation("/room404");
      else {
        const { url } = room;
        setCallObject(call);
        callObjectRef.current = call;
      }
    })();
  }, [user, roomId, callObject]);

  const leaveCall = async () => {
    await callObject?.leave();
  };

  useEffect(() => {
    return () => {
      (async () => await leaveCall())();
    };
  }, [callObject]);

  useEffect(() => {
    let joinTimestamp = Date.now();
    return () => {
      if (joinTimestamp && joinTimestamp > 0) {
        let now = Date.now();
        let sessionLength = now - joinTimestamp;
        // Don't trigger unless they've stayed in the room longer than 5 seconds
        // This will eliminate when react sometimes reloads the AV component as the room is loading
        if (sessionLength > 5000) {
          sendAnalyticsEvent("leave_room", {
            room_duration_ms: sessionLength,
            room_num_users: numOthers || 0,
            room_name: roomId,
          });
        }
      }
    };
  }, []);

  return !isAVLoaded ? (
    <></>
  ) : callObject ? (
    <DailyProvider callObject={callObject}>
      <AVControls
        useDevices={useDevices}
        setCameraOffAndMicMuted={setCameraOffAndMicMuted}
      />
      <StyledAVContainer>
        <SelfStream
          callObject={callObject}
          profilePic={profilePic}
          displayName={displayName}
          userId={userId}
          userName={userName}
          color={myColor ? myColor : fallbackColor}
        />
        <OtherStreams />
      </StyledAVContainer>
    </DailyProvider>
  ) : (
    <StyledAVContainer onClick={(e) => e.stopPropagation()}>
      {self && (
        <StyledStream
          style={{
            background: `linear-gradient(${
              myColor ? myColor : fallbackColor
            }, transparent)`,
            borderColor: myColor ? myColor : fallbackColor,
          }}
          className="sentry-block"
        />
      )}
      {otherConnectionIds.map((connectionId, i) => (
        <StyledStream
          key={connectionId}
          style={{
            background: `linear-gradient(transparent, transparent)`,
            borderColor: "transparent",
          }}
          className="sentry-block"
        />
      ))}
    </StyledAVContainer>
  );
});

const OtherStreams = memo(function OtherStreams() {
  const remoteParticipantIds = useParticipantIds({ filter: "remote" });
  return (
    <>
      <AnimatePresence>
        {remoteParticipantIds.map((participantId) => (
          <Stream id={participantId} key={participantId} />
        ))}
      </AnimatePresence>
    </>
  );
});

const SelfStream = memo(function SelfStream({
  profilePic,
  displayName,
  userId,
  userName,
  color,
}: {
  profilePic: string;
  displayName: string;
  userId: string;
  userName: string;
  callObject: DailyCall;
  color: string;
}) {
  /* This is for displaying our self-view. */
  const localParticipant = useLocalParticipant();
  const localParticipantVideoTrack = useVideoTrack(
    localParticipant?.session_id || ""
  );
  const localVideoElement = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (
      !localParticipantVideoTrack.persistentTrack ||
      !localVideoElement.current
    )
      return;
    localVideoElement.current.srcObject =
      (localParticipantVideoTrack.persistentTrack &&
        new MediaStream([localParticipantVideoTrack?.persistentTrack])) ||
      null;
  }, [localParticipantVideoTrack.persistentTrack]);

  if (!localParticipant) return null;
  return (
    <StreamContainer
      layoutId={"stream-self"}
      isMuted={!localParticipant?.audio}
      isCameraOff={!localParticipant?.video}
      imgUrl={`url("/images/profile-pics/${
        profilePic
          ? profilePic
          : (localParticipant?.userData as any)?.profilePic
      }")`}
      color={color}
      videoElement={
        <video autoPlay muted playsInline ref={localVideoElement} />
      }
      displayName={displayName ? displayName : "you"}
      userId={userId ? userId : ""}
      userName={userName}
      isSelfStream={true}
    ></StreamContainer>
  );
});

const StreamContainer = memo(function StreamContainer({
  layoutId,
  imgUrl,
  color,
  videoElement,
  audioElement,
  displayName,
  userId,
  userName,
  isMuted,
  isCameraOff,
  isSelfStream,
}: {
  layoutId: string;
  imgUrl: string;
  color: string;
  isMuted: boolean;
  isCameraOff: boolean;
  videoElement: ReactElement;
  audioElement?: ReactElement;
  displayName?: string;
  userId?: string;
  userName?: string;
  isSelfStream?: boolean;
}) {
  const [favoritesUpdated, setFavoritesUpdated] = useState(true);
  const [faved, setFaved] = useState(false);
  const [isShowingButtons, setIsShowingButtons] = useState(false);
  const { isOnMobile, isMobileMessageActive } = useSnapshot(localState);

  const { user, addOrRemoveFavorite } = useContext(AuthContext)

  useEffect(() => {
    if (!userId || !user) return;
    (async () => {
      setFavoritesUpdated(false);
      const imf = user.favorites.indexOf(userId) >= 0;
      setFaved(imf);
    })();
  }, [userId]);
  useEffect(() => {
    if (!userId || !favoritesUpdated) return;
    setFaved(!faved);
    setFavoritesUpdated(false);
  }, [favoritesUpdated]);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      setIsShowingButtons(false);
    };

    window.addEventListener("click", handleClick);
    return () => window.removeEventListener("click", handleClick);
  }, []);

  return (
    <StyledStream
      id={`user-stream-${userName}`}
      layoutId={layoutId}
      isCameraOff={isCameraOff}
      isShowingButtons={isShowingButtons}
      isMobileMessageActive={isMobileMessageActive}
      onClick={(e) => {
        if (isOnMobile) {
          setIsShowingButtons(!isShowingButtons);
        }
        e.stopPropagation();
      }}
      onPointerOver={(e) => {
        if (!isOnMobile) {
          setIsShowingButtons(true);
        }
        e.stopPropagation();
      }}
      onPointerOut={(e) => {
        if (!isOnMobile) {
          setIsShowingButtons(false);
        }
        e.stopPropagation();
      }}
      style={{
        position: "relative",
        backgroundImage: imgUrl,
        backgroundSize: "cover",
        backgroundPosition: "50% 50%",
        borderColor: color,
      }}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      className="sentry-block"
    >
      <>
        {isMuted ? <MuteIndicator /> : ""}
        {videoElement}
        {audioElement}
        {userId && (
          <div className="user-buttons-container">
            {!isSelfStream && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  (async () => {
                    const res = await addOrRemoveFavorite(userId).then(() =>
                      setFavoritesUpdated(true)
                    );
                  })();
                }}
                className="fave-button btn"
              >
                {faved ? (
                  <img src="/images/room/heart-full.svg"></img>
                ) : (
                  <img src="/images/room/heart.svg"></img>
                )}
                <div className="tooltip">
                  <img className="point" src="/images/room/point.svg"></img>
                  {!faved ? "Save profile" : "Saved"}
                </div>
              </div>
            )}
            <a
              className="profile-button btn"
              href={`${
                window.location.protocol +
                "//" +
                window.location.host +
                "/profile/" +
                userName
              }`}
              target="_blank"
            >
              <img src="/images/room/link-out.svg"></img>
              <div className="tooltip">
                <img className="point" src="/images/room/point.svg"></img>
                {"View profile"}
              </div>
            </a>
          </div>
        )}
        {displayName && (
          <div className="user-name-container-container">
            <div className="user-name-container">
              <img className="point" src="/images/room/point.svg" />
              <div className="user-name">{displayName}</div>{" "}
            </div>
          </div>
        )}
      </>
    </StyledStream>
  );
});

const Stream = memo(function Stream({ id }: { id: string }) {
  const participant = useParticipant(id);
  const videoTrack = useMediaTrack(id, "video");
  const lastVT = useRef<any>();
  const audioTrack = useMediaTrack(id, "audio");
  const lastAT = useRef<any>();

  const videoElement = useRef<HTMLVideoElement>(null);
  const audioElement = useRef<HTMLAudioElement>(null);
  useEffect(() => {
    // HACK: For some reason videoTrack rerenders every time cursor moves, track this referential equality and only mount new stream if it changes
    /*  The track is ready to be played. We can show video of the remote participant in the UI.*/
    if (
      videoTrack?.state === "playable" &&
      videoElement.current &&
      videoTrack.persistentTrack &&
      lastVT.current?.persistentTrack?.id !== videoTrack.persistentTrack.id
    ) {
      videoElement.current.srcObject = new MediaStream([
        videoTrack.persistentTrack,
      ]);
      lastVT.current = videoTrack;
    }
  }, [videoTrack]);

  useEffect(() => {
    // HACK: For some reason videoTrack rerenders every time cursor moves, track this referential equality and only mount new stream if it changes
    if (lastAT.current === audioTrack) return;
    if (
      audioTrack?.state === "playable" &&
      audioElement.current &&
      audioTrack.persistentTrack &&
      lastAT.current?.persistentTrack?.id !== audioTrack.persistentTrack.id
    ) {
      audioElement.current.srcObject = new MediaStream([
        audioTrack.persistentTrack,
      ]);
      lastAT.current = audioTrack;
    }
  }, [audioTrack]);

  return (
    <StreamContainer
      layoutId={"stream" + id}
      isMuted={!participant?.audio}
      isCameraOff={!participant?.video}
      imgUrl={`url("/images/profile-pics/${
        (participant?.userData as any)?.profilePic
      }")`}
      color={
        (participant?.userData as any)?.color
          ? (participant?.userData as any)?.color
          : "transparent"
      }
      videoElement={
        videoTrack ? (
          <video autoPlay muted playsInline ref={videoElement} />
        ) : (
          <></>
        )
      }
      audioElement={
        audioTrack ? <audio autoPlay playsInline ref={audioElement} /> : <></>
      }
      displayName={
        (participant?.userData as any)?.displayName
          ? (participant?.userData as any)?.displayName
          : ""
      }
      userId={
        (participant?.userData as any)?.userId
          ? (participant?.userData as any)?.userId
          : null
      }
      userName={
        (participant?.userData as any)?.userName
          ? (participant?.userData as any)?.userName
          : null
      }
    ></StreamContainer>
  );
});

const StyledAVContainer = styled("div", {
  position: "fixed",
  inset: "1rem",
  bottom: "auto",
  display: "flex",
  gap: "1rem",
  "@mobile": {
    gap: "0.5rem",
  },
  placeContent: "center",
  zIndex: "1",
  pointerEvents: "none",
});

const StyledStream = styled(motion.div, {
  pointerEvents: "all",
  width: "6rem",
  height: "6rem",
  background: "rgba(0,0,0,.1)",
  borderRadius: "999rem",
  border: "2px solid rgba(0,0,0,.5)",
  position: "relative",
  marginTop: "$navHeight",
  cursor: "pointer",

  "& video": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "999rem",
    transform: "rotateY(180deg)",
    filter: "grayscale(1)",
  },
  "& .user-buttons-container": {
    width: "58px",
    borderRadius: "9px",
    "@mobile": {
      width: "64px",
    },
    backgroundColor: "$wash",
    position: "absolute",
    display: "flex",
    height: "30px",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",

    "& .btn": {
      height: "24px",
      width: "24px",

      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px",
      "@mobile": {
        transform: "scale(1.2)",
        padding: "6px",
      },
      "&:hover": {
        "@notmobile": {
          backgroundColor: "$gray200",
        },

        "& .tooltip": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
      "& .tooltip": {
        padding: "4px 0 4px 0",
        position: "absolute",
        fontSize: "10px",
        width: "80px",
        bottom: "40px",
        backgroundColor: "black",
        textAlign: "center",
        color: "white",
        display: "none",
        borderRadius: "6px",
        "& .point": {
          position: "absolute",
          bottom: "-10px",
          left: 0,
          right: 0,
          margin: "auto",
          width: "12px",
          transform: "rotate(90deg)",
        },
      },
      "& img": {
        height: "20px",
        width: "20px",
      },
    },
  },
  "& .user-name-container-container": {
    display: "flex",
    justifyContent: "center",
    width: "50px",
    height: "2px",
    position: "absolute",
    zIndex: "3",
    left: 0,
    right: 0,
    margin: "auto",

    "& .user-name-container": {
      display: "flex",
      flexDirection: "column",
      "& .user-name": {
        backgroundColor: "black",
        borderRadius: "9px",
        top: "8px",
        color: "white",
        marginTop: "-3px",
        padding: "4px 10px 6px 10px",
        fontSize: "12px",
        fontWeight: "200",
        textAlign: "center",
        width: "auto",
        whiteSpace: "nowrap",
      },
      "& .point": {
        transform: "rotate(-90deg)",
        alignSelf: "center",
      },
    },
  },
  variants: {
    isCameraOff: {
      true: {
        "& video": {
          opacity: 0,
        },
      },
    },
    isShowingButtons: {
      true: {},
      false: {
        "& .user-name-container-container, & .user-buttons-container": {
          display: "none",
        },
      },
    },
    isMobileMessageActive: {
      true: {
        marginTop: "85px",
      },
    },
  },
});
