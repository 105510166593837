import { useEffect, useState, useContext } from "react";
import { localState } from "~/state/state";

import { keyframes, styled } from "~/ui/style/stitches.config";
import { Button } from "../components/Button";
import {
  useMutateStorage,
  useOthers,
  useStorage,
} from "~/state/liveblocks.config";
import { shallow } from "@liveblocks/client";
import { AuthContext } from "~/utils/useAuth";
import { getUser } from "~/api/userApi";
import { useSnapshot } from "valtio";
import { PlusBadge } from "./BackgroundMenu";

export default function CardSearchMenu() {
  const theme = useStorage((root) => root.theme);
  const deck = useStorage((root) => root.deck, shallow);
  const isCardSearchOpen = useStorage((root) => root.isCardSearchOpen);
  const searchTerm = useStorage((root) => root.searchTerm);
  const activeSearcher = useStorage((root) => root.activeSearcher);
  const [inputValue, setInputValue] = useState(searchTerm);
  const { user } = useContext(AuthContext);
  const updateStorage = useMutateStorage();
  const activeUserIsMe = user?.uuid.trim() === activeSearcher;
  const canEditText = activeUserIsMe || activeSearcher === "";
  const others = useOthers();
  const [isUnmounting, setIsUnmounting] = useState(false);
  const [prevOthersLength, setPrevOthersLength] = useState(0);
  const { isOnPlusPlan } = useSnapshot(localState);
  // if someone left the call, reset the text box
  useEffect(() => {
    if (prevOthersLength > others.length && activeSearcher !== "") {
      updateStorage((storage) => storage.set("activeSearcher", ""));
      document.getElementById("card-search-input")?.blur();
    }
    setPrevOthersLength(others.length);
  }, [others.length]);

  useEffect(() => {
    updateStorage((storage) => {
      storage.set("searchTerm", inputValue);
    });
  }, [inputValue]);

  useEffect(() => {
    if (!activeUserIsMe) setInputValue(searchTerm);
  }, [searchTerm]);

  const [activeUserName, setActiveUserName] = useState("");

  useEffect(() => {
    (async function () {
      if (activeSearcher === "") {
        setActiveUserName("");
        return;
      }
      const activeUser = await getUser(activeSearcher);
      if (activeUser) setActiveUserName(activeUser.name);
    })();
  }, [activeSearcher]);

  return (
    <StyledCardSearchMenu Theme={theme}>
      <Button
        Theme={theme}
        role="menuitem"
        className="arrow-button"
        style={{ padding: "3px 6px", marginTop: "3px", marginRight: "6px" }}
        transparent
        onClick={() => {
          updateStorage((storage) => {
            storage.set("isCardSearchOpen", !isCardSearchOpen);
          });
        }}
      >
        &larr;
      </Button>
      <div style={{ width: "100%", position: "relative" }}>
        <div
          style={{
            fontSize: "11px",
            textAlign: "left",
            marginLeft: "10px",
            marginBottom: "10px",
          }}
        >
          Search cards
        </div>
        <PlusBadge
          clickable
          isOnPlusPlan={isOnPlusPlan}
          Theme={theme}
          style={{ position: "absolute", top: "-2px", right: "8px" }}
        >
          PLUS
        </PlusBadge>
      </div>
      <input
        id={"card-search-input"}
        autoFocus
        disabled={!canEditText || !isOnPlusPlan}
        value={inputValue}
        maxLength={70}
        onBlur={() =>
          updateStorage((storage) => {
            if (activeUserIsMe) storage.set("activeSearcher", "");
          })
        }
        placeholder={
          deck.name === "Viator Somniorum"
            ? "Enter card name"
            : "Keyword eg. 'majors'"
        }
        onChange={(e) => {
          if (!canEditText) return;
          else setInputValue(e.target.value);
        }}
        onKeyDown={(e) => {
          updateStorage((storage) =>
            storage.set("activeSearcher", user?.uuid.trim() ?? "")
          );
          e.stopPropagation();
        }}
      ></input>
      <div
        className="description"
        style={{ position: "relative", height: "40px", width: "100%" }}
      >
        <div
          className="inner-description"
          style={{
            opacity:
              activeUserName !== "" && activeUserName !== user?.name
                ? "1"
                : "0",
            position: "absolute",
            top: 0,
            left: 0,
            transition: "opacity 0.4s",
          }}
        >
          {activeUserName} is searching
          <span id="one" className="dot">
            .
          </span>
          <span id="two" className="dot">
            .
          </span>
          <span id="three" className="dot">
            .
          </span>
        </div>
        <div
          className="inner-description"
          style={{
            opacity:
              activeUserName !== "" && activeUserName !== user?.name
                ? "0"
                : "1",
            position: "absolute",
            top: 0,
            left: 0,
            transition: "opacity 0.4s",
          }}
        >
          {deck.name === "Viator Somniorum" ? (
            "Shows specific cards from this oracle deck"
          ) : (
            <>
              Shows specific cards, like{" "}
              <span style={{ fontStyle: "italic" }}>
                'wands', 'fours', 'ace of cups'
              </span>
            </>
          )}
        </div>
      </div>
      {!isOnPlusPlan && (
        <Button
          lightweight
          Theme={theme}
          style={{
            width: "calc(100% - 16px)",
            marginLeft: "0px",
            marginTop: "4px",
            marginBottom: "0px",
          }}
          onClick={() => {
            window.open("/plans", "_blank");
          }}
        >
          Enable
        </Button>
      )}
    </StyledCardSearchMenu>
  );
}

const animateDotOne = keyframes({
  "0%": {
    opacity: 0,
  },
  "33%": {
    opacity: 1,
  },
  "100%": {
    opacity: 1,
  },
});

const animateDotTwo = keyframes({
  "0%": {
    opacity: 0,
  },
  "33%": {
    opacity: 0,
  },
  "66%": {
    opacity: 1,
  },
  "100%": {
    opacity: 1,
  },
});
const animateDotThree = keyframes({
  "0%": {
    opacity: 0,
  },
  "66%": {
    opacity: 0,
  },
  "99%": {
    opacity: 1,
  },
});

const StyledCardSearchMenu = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: "10px",
  marginTop: "16px",
  "& input": {
    maxWidth: "calc(100% - 16px)",
    fontSize: "11px",
    paddingLeft: "8px",
    lineHeight: "30px",
    borderRadius: "9px",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    "&:focus": {
      border: "1px solid black",
      outline: "none",
    },
    "&:disabled": {
      opacity: "0.4 !important",
      pointerEvents: "none",
    },
  },
  "& .description": {
    fontSize: "10px",
    maxWidth: "calc(100% - 20px)",
    marginTop: "8px",
    fontStyle: "italic",
    "& .dot": {
      marginLeft: "0.5px",
      opacity: 1,
      "&#one": {
        animation: `${animateDotOne} 1.5s infinite linear`,
      },
      "&#two": {
        animation: `${animateDotTwo} 1.5s infinite linear`,
      },

      "&#three": {
        animation: `${animateDotThree} 1.5s infinite linear`,
      },
    },
  },

  variants: {
    Theme: {
      light: {},
      dark: {
        "& input": {
          backgroundColor: "black",
          border: "1px solid rgba(255, 255, 255, 0.3)",
          "&:focus": {
            border: "1px solid white",
          },
          "&::placeholder": {
            color: "rgba(255, 255, 255, 0.5)",
          },
        },
      },
    },
  },
});
