import { ChevronDownIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { styled } from "~/ui/style/stitches.config";

export const ExpandingSection = ({
  title,
  text,
}: {
  title: string;
  text: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <StyledExpandingSection>
      <div onClick={() => setIsExpanded(!isExpanded)} className="title">
        {title}
        <span style={{ width: "4px" }}></span>
        <ChevronDownIcon
          style={{
            transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
            transition: "all 0.2s ease-in-out",
          }}
        />
      </div>
      {isExpanded && (
        <div dangerouslySetInnerHTML={{ __html: text }} className="contents" />
      )}
    </StyledExpandingSection>
  );
};

const StyledExpandingSection = styled("div", {
  pointerEvents: "all",
  position: "relative",
  marginTop: "16px",
  marginBottom: "16px",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  "& .title": {
    fontFamily: "WorkSans",
    fontSize: "12px",
    cursor: "pointer",
    justifyContent: "center",
    marginTop: "10px",
    pointerEvents: "all",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    left: 0,
    right: 0,
    margin: "auto",
    width: "200px",
    textAlign: "center",
    transition: "all 0.2s ease-in-out",
    // zIndex: "20",
    "&:hover": {
      opacity: "0.5",
    },
  },
  "& .contents": {
    fontSize: "12px",
    marginTop: "32px",
    width: "68%",
    "& .a": {
      fontFamily: "GorgeousBaby",
      fontSize: "30px",
      lineHeight: "22px",
    },
  },
});
