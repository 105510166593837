import { Vec2d } from "../../utils/useMousePosition";

export type ProfilePicData = {
  img: string;
  name?: string;
  line?: string;
  article?: string;
}

export const freeDeckIds = ['rider-waite-smith', 'marseille', 'i-ching']

export type Deck = {
  id: string;
  name: string;
  basePath: string;
  preview: string;
  creator: string;
  location: string;
  description: string;
  link: string;
  backArtwork?: string;
  fallbackColor: string;
  shuffleButtonTheme?: RoomTheme;
  themeBackgroundSrc?: string;
  foilPath?: string;
  translations?: string[];
  border: number;
  borderColor: CardBorderColor;
  featuredCard?: string;
  storeBackground?: string;
  tags?: string[];
  background: string;
  cardSize: Vec2d;
  cards: DeckCard[];
  year: string;
  boxColors? : {
    left: string;
    right: string;
    back: string;
    border?: string;
    cardFallback?: string;
  };
  shopCardNames: string[];
};
export type DeckCard = {
  name: string;
  artwork: string;
  filePath: string;
  size: [number, number];
  description: string;
  keywords: string[];
  descriptionReverse: string;
  keywordsReverse: string[];
  searches: string[];
};
export type SpreadCard = {
  filePath: string;
  size: [number, number];
  border: number;
  borderColor: CardBorderColor;
  fallbackColor?: string;
  foilPath?: string;
  deckName: string;
  backFilePath: string | null;
  position: Vec2d;
  rotation: number;
  open: boolean;
  focused: boolean;
  reversed: boolean;
  activeUser: number | null;
  searches: string[];
};
export type Spread = {
  name: string;
  description: string;
  size: Vec2d;
  slots: Array<{
    name: string;
    description: string;
    position: Vec2d;
    rotated: boolean;
    cards: string[] | null | undefined;
  }>;
};
export type Shape = {
  color: string;
  positions: [number, number][] | [];
  id: string;
};

export type SpreadText = {
    position: [number, number];
    text: string;
    id: string;
    owner: string;
}
export enum RoomState {
  Ready,
  Shuffle,
  Draw
}
export enum SideMenu {
  Artwork,
  Decks,
  Spreads
}
export type CardBorderColor = {
    light: string,
    dark: string
}

export type RoomTheme = "light" | "dark"

export enum RoomEvent {
  JOIN = 'JOIN',
  LEAVE = 'LEAVE'
}