import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { localState } from "~/state/state";
import { styled } from "~/ui/style/stitches.config";

export const MobileMessage = () => {
  const { inDarkMode, isOnMobile, isMobileMessageActive } =
    useSnapshot(localState);

  useEffect(() => {
    if (isOnMobile) localState.isMobileMessageActive = true;
  }, [isOnMobile]);

  return isMobileMessageActive ? (
    <StyledMobileMessage inDarkMode={inDarkMode}>
      <div className="text">
        <span className="bold">Our mobile site is experimental!</span> You’re
        welcome to try, though we recommend using desktop.
      </div>
      <img
        className="x-icon"
        onClick={() => (localState.isMobileMessageActive = false)}
        src="/images/room/x-icon.png"
      ></img>
    </StyledMobileMessage>
  ) : (
    <></>
  );
};

const StyledMobileMessage = styled("div", {
  backgroundColor: "$wash",
  zIndex: "99",
  fontSize: "12px",
  border: "1px solid black",
  borderRadius: "12px",
  maxWidth: "calc(100% - 20px)",
  marginLeft: "10px",
  marginTop: "calc($navHeight + 8px)",
  position: "relative",
  color: "black",
  padding: "8px 11px",
  paddingRight: "20px",
  "& .bold": {
    fontWeight: "500",
  },
  "& .x-icon": {
    position: "absolute",
    filter: "invert(1)",
    top: 0,
    right: 0,
    width: "32px",
    padding: "6px",
    marginTop: "-3px",
  },
  variants: {
    inDarkMode: {
      true: {
        backgroundColor: "$darkwash",
        border: "1px solid white",
        color: "white",
        "& .x-icon": {
          filter: "none",
        },
      },
    },
  },
});
