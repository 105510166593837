import { useSnapshot } from "valtio";
import { localState } from "~/state/state";

export const HelpButton = ({
  text,
  isOnBillingPage,
  isOnProPage,
}: {
  text?: string;
  isOnBillingPage?: boolean;
  isOnProPage?: boolean;
}) => {
  const { isInRoom, inDarkMode } = useSnapshot(localState);
  let backgroundColor = inDarkMode ? "black" : "white";
  const color = inDarkMode ? "white" : "black";
  if (isOnProPage) {
    backgroundColor = inDarkMode
      ? "var(--colors-darkwashA500)"
      : "var(--colors-washA500)";
  }

  let border = `1px solid ${color}`;
  if (isOnProPage) {
    border = `1px solid ${
      inDarkMode ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)"
    }`;
  }

  const fontSize = isOnProPage ? "15px" : "11px";

  const borderRadius = isOnProPage ? "50%" : "12px";

  const padding = isOnProPage ? "6px 11px" : "6px 10px";

  return (
    <div
      style={{
        position: "fixed",
        bottom: isOnBillingPage ? "28px" : `66px`,
        right: isOnBillingPage ? "26px" : "16px",
        cursor: "pointer",
        zIndex: isInRoom ? "unset" : "4",
        marginBottom: isOnProPage ? "-6px" : "unset",
      }}
      dangerouslySetInnerHTML={{
        __html: ` <button
        id="support"
class="custom-button"
style="background-color: ${backgroundColor}; color: ${color}; border: ${border}; cursor: pointer; border-radius: ${borderRadius}; font-size: ${fontSize}; padding: ${padding}"
onclick="videoask.loadModal('https://www.videoask.com/fcaqxaw1l')"
>
${text ? text : "Feedback"}
</button>`,
      }}
    />
  );
};

export const HelpButtonSmall = () => {
  const { isInRoom, inDarkMode } = useSnapshot(localState);
  const backgroundColor = inDarkMode ? "black" : "white";
  const color = inDarkMode ? "white" : "black";
  const filter = inDarkMode ? "invert(1)" : "none";
  return (
    <div
      style={{}}
      dangerouslySetInnerHTML={{
        __html: ` <button
class="custom-button"
style="background-color: ${backgroundColor}; color: ${color}; border: 1px solid ${color}; cursor: pointer;  border-radius: 50%;  width:30px; height:30px; display:flex; padding:0; justify-content:center; align-items: center;"
onclick="videoask.loadModal('https://www.videoask.com/fcaqxaw1l')"
>
<img style="filter: ${filter}" src="/images/room/question.svg">
</button>`,
      }}
    />
  );
};
