import { useSnapshot } from "valtio";
import { useStorage } from "~/state/liveblocks.config";
import { localState } from "~/state/state";
import { Button } from "../components/Button";
import { MAX_ZOOM_INDEX, MIN_ZOOM_INDEX } from "~/utils/consts";
import { motion } from "framer-motion";
import { keyframes, styled } from "~/ui/style/stitches.config";

export function SpreadNav() {
  const theme = useStorage((root) => root.theme);
  const { zoomIndex, isOnMobile } = useSnapshot(localState);

  const arrowImg = isOnMobile
    ? "/images/room/arrow-mobile.png"
    : "/images/room/arrow.png";
  const middleImg = isOnMobile
    ? "/images/room/middle-mobile.png"
    : "/images/room/middle.png";

  const zoomAndPanButtons = (
    <>
      {" "}
      <div id="zoom-btnz">
        <Button
          Theme={theme}
          css={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            padding: "$1 $3",
          }}
          onClick={() => {
            if (zoomIndex > MIN_ZOOM_INDEX) localState.zoomIndex--;
          }}
        >
          &minus;
        </Button>
        <Button
          Theme={theme}
          css={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            marginLeft: "-3px",
            padding: "$1 $3",
          }}
          onClick={() => {
            if (zoomIndex < MAX_ZOOM_INDEX) localState.zoomIndex++;
          }}
        >
          +
        </Button>
      </div>
      <div
        className="pan-buttons"
        style={{ display: "flex", marginTop: "10px" }}
      >
        <StyledPanButton
          Theme={theme}
          src={arrowImg}
          style={{
            transform: "rotate(90deg)",
          }}
          onClick={() =>
            (localState.pan = [localState.pan[0] + 1, localState.pan[1]])
          }
        />
        <div
          className="middle-arrows"
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "-4px",
            marginLeft: "-6px",
          }}
        >
          <StyledPanButton
            src={arrowImg}
            Theme={theme}
            style={{
              transform: "rotate(180deg)",
              marginRight: "-2px",
            }}
            onClick={() =>
              (localState.pan = [localState.pan[0], localState.pan[1] + 1])
            }
          />
          <div style={{ height: "2px" }} />
          <StyledPanButton
            src={middleImg}
            Theme={theme}
            style={{ marginTop: "-4px", marginBottom: "-4px" }}
            onClick={() => (localState.pan = [0, 0])}
          />
          <div style={{ height: "2px" }} />

          <StyledPanButton
            Theme={theme}
            src={arrowImg}
            onClick={() =>
              (localState.pan = [localState.pan[0], localState.pan[1] - 1])
            }
          />
        </div>
        <StyledPanButton
          Theme={theme}
          src={arrowImg}
          style={{ transform: "rotate(270deg)" }}
          onClick={() =>
            (localState.pan = [localState.pan[0] - 1, localState.pan[1]])
          }
        />
      </div>
    </>
  );

  return (
    <StyledZoomButtons id="zoom-buttons">{zoomAndPanButtons}</StyledZoomButtons>
  );
}

const fadein = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const StyledZoomButtons = styled("div", {
  animation: `${fadein} 1s`,
  position: "absolute",
  zIndex: "2",
  top: "calc($navHeight + 20px)",
  right: "8px",
  whiteSpace: "nowrap",
  "@mobile": {
    top: "unset !important",
    bottom: "calc($navHeight + 20px)",
    display: "flex",
    flexDirection: "column-reverse",
    right: 0,
    "#zoom-btnz": {
      marginTop: "30px",
      transform: "scale(1.5)",
      transformOrigin: "8px 30px",
      "& button": {
        borderWidth: "0.7px",
      },
    },
    ".pan-buttons": {
      paddingRight: "6px",
    },
  },
});

const StyledPanButton = styled("img", {
  cursor: "pointer",
  width: "25px !important",
  "@mobile": {
    width: "36px !important",
  },
  flexShrink: 0,
  alignSelf: "center",
  margin: "0",
  marginLeft: "0 !important",

  variants: {
    Theme: {
      dark: {
        filter: "invert(1)",
      },
      light: {},
    },
  },
});
