import { useContext, useEffect, useState } from "react";
import { useLocation } from "wouter";
import { AuthContext } from "../utils/useAuth";
import { handleCreateRoom } from "~/api/roomApi";
import { keyframes, styled } from "~/ui/style/stitches.config";
import { useSnapshot } from "valtio";
import { localState } from "~/state/state";
import { BigButton } from "~/ui/components/BigButton";
import { motion } from "framer-motion";
import { FavesBar } from "~/ui/menus/FavesBar";
import { HelpButton } from "~/ui/components/HelpButton";
import { ProDirectory } from "./marketplace/ProDirectory";
import { DeckMarketplace } from "./marketplace/DeckMarketplace";
import { UserProfile } from "~/api/userApi";
import { Button } from "~/ui/components/Button";
import { HomeNavBar } from "~/ui/home/HomeNavBar";
import { ProChecklistSection } from "~/ui/home/ProChecklist";
import { ExpandingSection } from "~/ui/home/ExpandingSection";
import { ProfessionalDashboard } from "./marketplace/Availability";
import { Upcoming } from "./marketplace/Upcoming";
import { Marquee } from "~/ui/components/Marquee";
import { GCalTest } from "./gcal-test";
import allProPicsJson from "../preloading/allProPics.json";
import { ProPayoutsOverlay } from "~/ui/home/ProPayoutsOverlay";
import { Message } from "~/ui/components/Message";
import { CartOverlay } from "./marketplace/DeckOverlay";

export enum MOONLIGHT_PAGE {
  HOME,
  DECKS,
  BOOK,
  AVAILABILITY,
  UPCOMING,
  GCAL_TEST,
}

const Home = ({
  user,
  page,
}: {
  user: UserProfile | null;
  page: MOONLIGHT_PAGE;
}) => {
  const { isConnecting, isOnMobile } = useSnapshot(localState);
  const [isTransitioningToRoom, setIsTransitioningToRoom] = useState(false);
  const [isHoveringButton, setIsHoveringButton] = useState(false);
  const [isFavesBarOpen, setIsFavesBarOpen] = useState(false);
  const [activePage, setActivePage] = useState<MOONLIGHT_PAGE>(
    MOONLIGHT_PAGE.HOME
  );
  const [isPayoutsOverlayOpen, setIsPayoutsOverlayOpen] = useState(false);
  const [shouldLongFadeIn, setShouldLongFadeIn] = useState(true);
  const [location, setLocation] = useLocation();
  const auth = useContext(AuthContext);
  const [fourRandomProPics, setFourRandomProPics] = useState<string[]>([
    "",
    "",
    "",
    "",
  ]);

  useEffect(() => {
    // get four random pro pics
    const allProPics = allProPicsJson as string[];
    const shuffledProPics = allProPics.sort(() => 0.5 - Math.random());
    const selectedProPics = shuffledProPics.slice(0, 4);
    setFourRandomProPics(selectedProPics);
  }, []);

  useEffect(() => {
    if (isTransitioningToRoom && !isConnecting) {
      window.setTimeout(() => {
        handleCreateRoom();
      }, 500);
    }
  }, [isTransitioningToRoom]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.page === "pros") {
      setLocation("/book");
    } else if (params.page === "decks") {
      setLocation("/decks");
    } else if (params.page === "availability") {
      setLocation("/availability");
    } else if (params.page === "upcoming") {
      setLocation("/upcoming");
    } else if (params.page === "gcal-test") {
      setLocation("/gcal-test");
    }
  }, [window.location.search]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.getElementById("main-container")!.scrollTop = 0;

    setActivePage(page);
  }, [page]);

  return (
    <div
      id="main-container"
      style={{
        position: "relative",
        width: "calc(100vw)",
        height: "calc(100vh)",
        overflow: "auto",
      }}
    >
      <Message />
      <CartOverlay />

      <ProPayoutsOverlay
        isOpen={isPayoutsOverlayOpen}
        setIsOpen={setIsPayoutsOverlayOpen}
      />
      <div
        style={{
          display: activePage === MOONLIGHT_PAGE.BOOK ? "block" : "none",
        }}
      >
        <ProDirectory />
      </div>
      <div
        style={{
          display: activePage === MOONLIGHT_PAGE.DECKS ? "block" : "none",
        }}
      >
        <DeckMarketplace />
      </div>
      <div
        style={{
          display:
            activePage === MOONLIGHT_PAGE.AVAILABILITY ? "block" : "none",
        }}
      >
        <ProfessionalDashboard activePage={activePage} />
      </div>
      <div
        style={{
          display: activePage === MOONLIGHT_PAGE.UPCOMING ? "block" : "none",
        }}
      >
        <Upcoming />
      </div>
      <div
        style={{
          display: activePage === MOONLIGHT_PAGE.GCAL_TEST ? "block" : "none",
        }}
      >
        <GCalTest />
      </div>
      <StyledDashboard
        onClick={(e) => {
          setIsFavesBarOpen(false);
        }}
        dontFade={!shouldLongFadeIn}
        style={{
          display: activePage === MOONLIGHT_PAGE.HOME ? "flex" : "none",
        }}
        onMouseMove={(e) => {
          // set x coordinate to offset from div
          //@ts-ignore
          document.getElementById("cursor-dot")!.style.left = e.clientX + "px";
          document.getElementById("cursor-dot")!.style.top = e.clientY + "px";
        }}
      >
        <div
          id="title-container"
          style={{
            display: activePage === MOONLIGHT_PAGE.HOME ? "flex" : "none",
            // overflow: "hidden",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <motion.div
            key="title"
            className="title"
            style={{ marginBottom: "12px", zIndex: 1 }}
          >
            Hello!
          </motion.div>
          <ProChecklistSection
            setIsPayoutsOverlayOpen={setIsPayoutsOverlayOpen}
            activePage={activePage}
          />
          <div className="homepage-section" style={{ position: "relative" }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                opacity: 1,
                clipPath: "inset(0 0 0 0)",
                display: "inherit",
                justifyContent: "inherit",
                alignItems: "inherit",
                flexDirection: "inherit",
              }}
            >
              <motion.div
                key={"subtitle"}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1 }}
                className="subtitle"
              >
                Start a new tarot room.
              </motion.div>

              <BigButton
                cursorClasses={`transitioning-${isTransitioningToRoom}`}
                onClick={() => setIsTransitioningToRoom(true)}
                enabled={!isFavesBarOpen}
                text={"CREATE A RITUAL"}
              />

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 1.5 }}
                key="taglines"
                className="taglines"
              >
                <div className="tagline">
                  <img src="/images/video.svg" />
                  <div className="text">video & audio included</div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        <div
          className="homepage-section"
          style={{ marginBottom: "150px", zIndex: 1 }}
        >
          <motion.div
            key={"subtitle"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 1 }}
            className="subtitle"
          >
            {user?.isProfessional
              ? "Explore our professional community."
              : "Book a professional tarot session."}
          </motion.div>
          <div className="tarot-pics">
            <img
              src={`/images/rotating-pros/${fourRandomProPics[0]}`}
              onClick={() => {
                window.open("/book", "_blank");
              }}
            />
            <img
              src={`/images/rotating-pros/${fourRandomProPics[1]}`}
              onClick={() => {
                window.open("/book", "_blank");
              }}
            />
            <img
              src={`/images/rotating-pros/${fourRandomProPics[2]}`}
              onClick={() => {
                window.open("/book", "_blank");
              }}
            />
            <img
              src={`/images/rotating-pros/${fourRandomProPics[3]}`}
              onClick={() => {
                window.open("/book", "_blank");
              }}
            />
          </div>
          <Button
            mobileSz14
            style={{
              fontSize: "12px",
              height: "40px",
              padding: 0,
              width: "160px",
              borderRadius: "20px",
              marginTop: "18px",
              border: "none",
            }}
            Theme={"dark"}
            onClick={() => setLocation("/book")}
          >
            {user?.isProfessional ? "See profiles" : "Find a reader"}
          </Button>
          <ExpandingSection
            title={
              user?.isProfessional
                ? "About our tarot community"
                : "Learn more about tarot"
            }
            text={
              user?.isProfessional
                ? "<span class='a'>W</span>elcome! We’re thrilled you’re here. Together we embrace a spectrum of styles and approaches to creating rituals, with shared philosophies to:<br/><br/>1. Empower people through the art of tarot<br/>2. Facilitate sessions with compassion and integrity<br/>3. Elevate tarot as a respected professional field<br/><br/>By gathering as professionals, we help tarot blossom. We're excited to share its magic and benefits with more people.<br/><br/>We'd love to hear your thoughts! Moonlight is crafted in conversation with the community."
                : "<span class='a'>T</span>arot is a playful and transformative tool for exploring possibilities, filled with beautiful visual metaphors. It's fun, illuminating, and empowering. If you're entering a new chapter, craving a fresh perspective, or seeking to deepen your curiosity, our community of professional readers & teachers is here for you."
            }
          />
        </div>

        {!isTransitioningToRoom && activePage === MOONLIGHT_PAGE.HOME && (
          <Marquee
            page={"home"}
            text={
              "<a class='marquee-text' href='https://www.instagram.com/entermoonlight/' target='_blank'>welcome to moonlight ・ follow us @entermoonlight</a>"
            }
          />
        )}

        {auth.user && activePage === MOONLIGHT_PAGE.HOME && (
          <FavesBar
            isOpen={isFavesBarOpen}
            setIsOpen={setIsFavesBarOpen}
          ></FavesBar>
        )}
      </StyledDashboard>
      <StyledHelpButton>
        <HelpButton />
      </StyledHelpButton>
      <HomeNavBar
        activePage={activePage}
        setActivePage={setActivePage}
        setShouldLongFadeIn={setShouldLongFadeIn}
        setIsPayoutsOverlayOpen={setIsPayoutsOverlayOpen}
      />
    </div>
  );
};

export default Home;

const fadeinNoDelay = keyframes({
  "0%": {
    opacity: 0,
    backgroundColor: "$wash",
  },

  "100%": {
    opacity: 1,
    backgroundColor: "$wash",
  },
});

const fadein = keyframes({
  "0%": {
    opacity: 0,
    backgroundColor: "$wash",
  },
  "25%": {
    opacity: 0,
    backgroundColor: "$wash",
  },
  "100%": {
    opacity: 1,
    backgroundColor: "$wash",
  },
});

export const StyledHelpButton = styled(motion.div, {
  "@mobile": {
    "& button": {
      transform: "translateY(-32px)",
    },
  },
});

const StyledDashboard = styled("div", {
  marginLeft: "calc($navWidth - 20px)",
  width: "calc(100% - $navWidth)",
  "@mobile": {
    marginLeft: "0px",
    width: "100%",
    "& #title-container": {
      width: "100%",
    },
    marginBottom: "70px",
    overflow: "hidden",
  },

  "& .homepage-section": {
    marginTop: "18px",
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    width: "500px",
    border: "0.5px solid rgba(0, 0, 0, 0.4)",
    borderRadius: "31px",
    boxShadow: "0px 3px 0px 0px rgba(0, 0, 0, 1)",
    padding: "30px 0px",
    alignItems: "center",
    "@mobile": {
      width: "calc(100% - 32px)",
    },
  },

  "& .tarot-pics": {
    marginTop: "30px",
    "& img": {
      width: "70px",
      height: "144px",
      objectFit: "cover",
      // center cover position of images
      borderRadius: "35px",
      border: "1px solid black",
      marginLeft: "2px",
      marginRight: "2px",
      cursor: "pointer",
    },
  },

  "& .help-button": {
    "@mobile": {
      "& button": {
        transform: "translateY(-56px)",
      },
      // transform: "translateY(-1px)",
    },
  },

  position: "relative",

  // height: "100vh",
  display: "flex",
  alignItems: "center",
  // justifyContent: "center",
  flexDirection: "column",
  backgroundColor: "$wash",
  "& .buttons": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "24px",
  },
  "& .button-container": {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& .subtext": {
      fontSize: "11px",

      marginTop: "14px",
    },
  },

  "& .title, & .subtitle": {
    zIndex: "100",
    pointerEvents: "none",
  },

  "& .title": {
    fontFamily: "GorgeousBaby",
    fontSize: "40px",
    marginTop: "130px",
    // marginTop: "-60px",

    "@mobile": {
      // fontSize: "44px",
      marginTop: "70px",
    },
  },

  "& .subtitle": {
    fontFamily: "KeplerLightCondensed",
    fontSize: "24px",
    marginTop: "10px",
    "@mobile": {
      marginTop: "8px",
      fontSize: "26px",
    },
  },
  "& .taglines": {
    display: "flex",
    zIndex: "10",

    "& .tagline": {
      marginLeft: "12px",
      marginRight: "12px",
      marginTop: "-12px",
      display: "flex",
      fontSize: "12px",
      "@mobile": {
        fontSize: "13px",
      },
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        marginRight: "4px",
      },
    },
    "@mobile": {
      flexDirection: "column !important",
      // marginTop: "-60px",

      "& .tagline": {
        // marginTop: "8px",
      },
    },
  },

  variants: {
    dontFade: {
      true: {
        animation: `${fadeinNoDelay} 0.4s`,
      },
      false: {
        animation: `${fadein} 3s`,
      },
    },
  },
});
