import { Button } from "~/ui/components/Button";
import * as paymentApi from "../api/paymentsApi";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "~/utils/useAuth";

export const GCalTest = () => {
  const { user } = useContext(AuthContext)
  const [readyToAcceptPayments, setReadyToAcceptPayments] = useState(false);
  const onboardConnectAccount = async (isUSA: boolean) => {
    await paymentApi.onboardConnectAccount({country: isUSA ? "US" : "CA"});
  }

  useEffect(() => {
    if (!user) return;
    const checkReadyToAcceptPayments = async () => {
      const { acceptingPayments } = user;
      setReadyToAcceptPayments(!!acceptingPayments);
    };
    checkReadyToAcceptPayments();
  }, [])

  return (
    <div style={{ height: "95vh", width: "100%", overflow: "scroll" }}>
      <div
        style={{
          padding: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>test page</h1>
        <br />
        <div>Create Connect account for logged in user</div>
        <Button onClick={() => onboardConnectAccount(true)}>Onboard USA Account</Button>
        <Button onClick={() => onboardConnectAccount(false)}>Onboard Non-USA Account</Button>
        <Button onClick={paymentApi.unlinkConnectAccount}>Unlink Connect Account from Profile</Button>
        {readyToAcceptPayments ? (<div>You are ready to accept payments</div>) : (<></>)}
      </div>
    </div>
  );
};
