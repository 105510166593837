import { LiveList } from "@liveblocks/client";
import { useMutateStorage, useStorage } from "~/state/liveblocks.config";
import { RoomState } from "~/types/room/types";
import { localState } from "~/state/state";

import { styled } from "~/ui/style/stitches.config";
import { useSnapshot } from "valtio";
import {
  CARD_SPREAD_SIZE,
  MOBILE_ZOOM_INDEX_MOD,
  PAN_BASE,
  ZOOM_BASE,
} from "~/utils/consts";
import { addV2d, mulV2d, subV2d } from "~/utils/math";
import { Vec2d } from "~/utils/useMousePosition";
import { PADDING_UNITS, withPadding } from "../room/Spread";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { MotionConfig, motion } from "framer-motion";
import { text } from "stream/consumers";

export const SpreadTextOverlay = ({ userId }: { userId: string }) => {
  const texts = useStorage((root) => root.texts);
  const {
    pan,
    inTypingMode,
    zoomIndex,
    isFocusedTyping,
    isOnMobile,
    isOnPlusPlan,
  } = useSnapshot(localState);
  const updateStorage = useMutateStorage();
  const spread = useStorage((root) => root.spread);
  const state = useStorage((root) => root.state);
  const zoomIndexMod = isOnMobile ? MOBILE_ZOOM_INDEX_MOD : 0;
  const panScreen = [pan[0] * PAN_BASE, pan[1] * PAN_BASE];
  const theme = useStorage((root) => root.theme);
  const zoomVector = [
    ZOOM_BASE ** (zoomIndex + zoomIndexMod),
    ZOOM_BASE ** (zoomIndex + zoomIndexMod),
  ] as Vec2d;
  const xPanWithZoom = panScreen[0] * ZOOM_BASE ** (zoomIndex + zoomIndexMod);
  const yPanWithZoom = panScreen[1] * ZOOM_BASE ** (zoomIndex + zoomIndexMod);

  const [activeTextId, setActiveTextId] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setIsFocused(false);
    setIsDragging(false);
  }, [inTypingMode]);

  const [clickOffset, setClickOffset] = useState<Vec2d>([0, 0]);

  useEffect(() => {
    const mostRecentText = texts[texts.length - 1];
    if (mostRecentText && mostRecentText.owner === userId) {
      document.getElementById(mostRecentText.id)?.focus();
    }
  }, [texts.length]);

  const handleDrag = (x: number, y: number, id: string) => {
    updateStorage((storage) =>
      storage.set(
        "texts",
        new LiveList(
          storage.get("texts").map((txt, j) => {
            const newPos = [
              (clickOffset[0] + x - window.innerWidth / 2 - panScreen[0]) /
                zoomVector[0],

              (clickOffset[1] + y - window.innerHeight / 2 - panScreen[1]) /
                zoomVector[1],
            ] as Vec2d;
            if (txt.id === id && x > 0 && y > 0) {
              return {
                ...txt,
                position: newPos,
              };
            } else {
              return {
                ...txt,
              };
            }
          })
        )
      )
    );
  };

  const handleDragStart = (x: number, y: number, e: DragEvent) => {
    setClickOffset([
      (e.target as HTMLElement).getBoundingClientRect().left - x + 30,
      (e.target as HTMLElement).getBoundingClientRect().top - y + 10,
    ]);
    e.dataTransfer?.setDragImage(emptyImg, 0, 0);
    (e.target as HTMLElement).blur();
  };

  const emptyImg = new Image();
  emptyImg.src =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=";
  return (
    <motion.div
      key="text-overlay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, delay: state === RoomState.Draw ? 2 : 0 }}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 2,
        pointerEvents: "none",
      }}
    >
      <img style={{ display: "none" }} src="/images/room/tiny-x.svg" />
      <div className="text-inner-container" key="text-inner-container">
        {texts.map((text, i) => (
          <StyledTextOverlay
            Theme={theme}
            key={text.id}
            isOnPlusPlan={isOnPlusPlan}
            style={{
              zIndex: i + 10,
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: `translate3d(${
                (text.position[0] + panScreen[0]) * zoomVector[0]
              }px, ${(text.position[1] + panScreen[1]) * zoomVector[1]}px, 0)`,
              pointerEvents: "auto",
            }}
          >
            <StyledTextContainer
              Theme={theme}
              isOnPlusPlan={isOnPlusPlan}
              className={`text-container isOwnedByOthers-${
                text.owner !== userId && text.owner !== ""
              }`}
              style={{
                transform: `scale(${1.2 ** (Math.max(zoomIndex, -2) + 1)})`,
                transformOrigin: "center left",
              }}
            >
              <StyledText
                Theme={theme}
                draggable={true}
                style={{
                  zIndex: "2",
                }}
                spellCheck={false}
                key={text.id}
                id={text.id}
                className={`spread-text 
               `}
                //@ts-ignore
                contentEditable={
                  isDragging || !isOnPlusPlan ? false : "plaintext-only"
                }
                suppressContentEditableWarning={true}
                onFocus={() => {
                  setIsFocused(true);
                  localState.isFocusedTyping = true;
                  setActiveTextId(text.id);
                  updateStorage((storage) =>
                    storage.set(
                      "texts",
                      new LiveList(
                        storage.get("texts").map((txt) => {
                          if (
                            txt.id === text.id &&
                            (txt.owner === "" || txt.owner === userId)
                          ) {
                            return {
                              ...txt,
                              owner: userId,
                            };
                          } else {
                            return {
                              ...txt,
                            };
                          }
                        })
                      )
                    )
                  );
                }}
                onKeyDownCapture={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    (e.target as HTMLElement).blur();
                    e.stopPropagation();
                  }
                }}
                onChange={(e) => {
                  if ((e.target as HTMLElement).innerText === "") {
                    // delete text from list
                    updateStorage((storage) =>
                      storage.set(
                        "texts",
                        new LiveList(
                          storage
                            .get("texts")
                            .filter((txt) => txt.id !== text.id)
                        )
                      )
                    );
                  } else {
                    updateStorage((storage) =>
                      storage.set(
                        "texts",
                        new LiveList(
                          storage.get("texts").map((txt) => {
                            if (txt.id === text.id && txt.owner === userId) {
                              return {
                                ...txt,
                                text: e.currentTarget.innerText,
                                owner: "",
                              };
                            } else {
                              return {
                                ...txt,
                              };
                            }
                          })
                        )
                      )
                    );
                  }
                }}
                onBlur={(e) => {
                  setIsFocused(false);
                  setActiveTextId(null);
                  if (e.target.innerText === "") {
                    // delete text from list
                    updateStorage((storage) =>
                      storage.set(
                        "texts",
                        new LiveList(
                          storage
                            .get("texts")
                            .filter((txt) => txt.id !== text.id)
                        )
                      )
                    );
                  } else {
                    updateStorage((storage) =>
                      storage.set(
                        "texts",
                        new LiveList(
                          storage.get("texts").map((txt) => {
                            if (txt.id === text.id && txt.owner === userId) {
                              return {
                                ...txt,
                                text: e.currentTarget.innerText,
                                owner: "",
                              };
                            } else {
                              return {
                                ...txt,
                              };
                            }
                          })
                        )
                      )
                    );
                  }
                }}
                onDragStart={(e) => {
                  //@ts-ignore
                  handleDragStart(e.clientX, e.clientY, e);
                  setIsDragging(true);
                  setActiveTextId(text.id);
                  e.stopPropagation();
                }}
                onTouchStart={(e) => {
                  // handle touch behavior on mobile the same way as mouse
                  handleDragStart(
                    e.touches[0].clientX,
                    e.touches[0].clientY,
                    //@ts-ignore
                    e
                  );
                  setIsDragging(true);
                  setActiveTextId(text.id);
                  e.stopPropagation();
                }}
                onDragEnd={(e) => {
                  setIsDragging(false);
                  setActiveTextId(null);
                }}
                onTouchEnd={(e) => {
                  setIsDragging(false);
                  setActiveTextId(null);
                }}
                onDrag={(e) => {
                  handleDrag(e.clientX, e.clientY, text.id);
                  e.stopPropagation();
                }}
                onTouchMove={(e) => {
                  handleDrag(
                    e.touches[0].clientX,
                    e.touches[0].clientY,
                    text.id
                  );
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  if (!(isFocused && activeTextId === text.id)) {
                    var range = document.createRange();
                    var sel = window.getSelection();
                    e.currentTarget.focus();
                    range.setStart(
                      e.currentTarget.childNodes[0],
                      (e.currentTarget as HTMLElement).innerText.length
                    );
                    sel!.removeAllRanges();
                    sel!.addRange(range);
                    (e.target as HTMLElement).focus();
                  }
                  e.stopPropagation();
                }}
                onMouseMove={(e) => {
                  e.stopPropagation();
                }}
                onPointerMove={(e) => {
                  e.stopPropagation();
                }}
                onPointerOver={(e) => {
                  localState.lockHover = true;
                }}
                onPointerDown={(e) => {
                  if (!(isFocused && activeTextId === text.id))
                    setIsDragging(true);
                  e.stopPropagation();
                }}
                onPointerUp={(e) => {
                  if (!(isFocused && activeTextId === text.id))
                    setIsDragging(false);
                }}
                onPointerOut={(e) => {
                  localState.lockHover = false;
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
              >
                {text.text}
              </StyledText>
              <img
                src="/images/room/tiny-x.svg"
                className="x-button"
                style={{
                  width: "16px",
                  height: "16px",
                  padding: "4px",
                  position: "absolute",
                  borderRadius: "4px",
                  right: "-18px",
                  top: "0px",
                  bottom: "0px",
                  margin: "auto",
                }}
                onPointerDown={(e) => {
                  localState.isFocusedTyping = false;
                  // e.stopPropagation();
                  updateStorage((storage) =>
                    storage.set(
                      "texts",
                      new LiveList(
                        storage.get("texts").filter((txt) => txt.id !== text.id)
                      )
                    )
                  );
                }}
              />
            </StyledTextContainer>
          </StyledTextOverlay>
        ))}
      </div>
    </motion.div>
  );
};

const StyledTextOverlay = styled("div", {
  position: "absolute",
  minWidth: "50px",
  variants: {
    Theme: {
      light: {},
      dark: {},
    },
    isOnPlusPlan: {
      true: {},
      false: {
        pointerEvents: "none !important",
      },
    },
  },
});

const StyledTextContainer = styled("div", {
  marginLeft: "-30px",
  marginTop: "-10px",
  position: "absolute",
  "&:hover .spread-text:not(:focus)": {
    backgroundColor: "$washA700",
    border: "1px solid $darkwashA500",
    opacity: 1,
  },

  "&:hover": {
    "& .x-button": {
      opacity: 1,
    },
  },

  "& .spread-text:focus + .x-button": {
    opacity: 1,
  },

  "& .x-button": {
    opacity: 0,
    cursor: "pointer !important",
    marginTop: "-6px",
    backgroundColor: "black",
    "&:hover": {
      backgroundColor: "$gray700",
    },
  },

  pointerEvents: "all",

  "&.isOwnedByOthers-true": {
    opacity: "0.3 !important",
    pointerEvents: "none !important",
    "& .spread-text:empty": {
      display: "none",
    },
  },
  variants: {
    isOnPlusPlan: {
      true: {},
      false: {
        pointerEvents: "none !important",
      },
    },
    Theme: {
      light: {},
      dark: {
        "&:hover .spread-text:not(:focus)": {
          backgroundColor: "$darkwashA700",
          border: "1px solid $washA500",
          opacity: 1,
        },
        "& .x-button": {
          // backgroundColor: "white !important",
          filter: "invert(1)",
          webkitFilter: "invert(1)",
        },
      },
    },
  },
});

const StyledText = styled("div", {
  webkitUserSelect: "text",
  userSelect: "text",
  cursor: "pointer",
  backgroundColor: "transparent",
  fontFamily: "KeplerLightCondensed",
  color: "black",
  fontSize: "25px",
  borderRadius: "9px",
  resize: "none",
  border: "1px solid transparent",
  padding: "6px 8px",
  paddingTop: "8px",

  "&:empty::before": {
    content: "Type here",
    color: "$gray500",
  },
  "&:empty": {
    width: "80px",
  },
  whiteSpace: "nowrap",

  "&:not(:focus)": {
    WebkitUserSelect: "none",
    userSelect: "none",
  },

  "&::placeholder": {
    color: "transparent",
  },
  "&:focus": {
    outline: "none",
    backgroundColor: "$washA700",
    border: "1px solid black",
    "&::placeholder": {
      color: "unset",
    },
  },
  variants: {
    Theme: {
      light: {},
      dark: {
        color: "white",
        "&:hover": {
          backgroundColor: "$darkwashA500",
          border: "1px solid $washA700",
          "&:focus": {
            border: "1px solid black",
          },
        },
        "&:focus": {
          border: "1px solid white",
          backgroundColor: "$darkwashA500",
          "&:focus": {
            border: "1px solid white",
          },
        },
      },
    },
  },
});
