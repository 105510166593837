import { styled } from "~/ui/style/stitches.config";
import { HomeSideBar } from "./HomeSideBar";
import { MOONLIGHT_PAGE } from "~/pages/Home";

export const HomeNavBar = ({
  activePage,
  setActivePage,
  setShouldLongFadeIn,
  onBillingPage,
  setIsPayoutsOverlayOpen,
}: {
  activePage: MOONLIGHT_PAGE;
  setActivePage: Function;
  setShouldLongFadeIn: Function;
  onBillingPage?: boolean;
  setIsPayoutsOverlayOpen: Function;
}) => {
  return (
    <>
      <StyledBottomNavBar>
        <SocialBottomBar />
      </StyledBottomNavBar>
      <HomeSideBar
        activePage={activePage}
        setActivePage={setActivePage}
        setShouldLongFadeIn={setShouldLongFadeIn}
        onBillingPage={onBillingPage}
        setIsPayoutsOverlayOpen={setIsPayoutsOverlayOpen}
      ></HomeSideBar>
    </>
  );
};

export const StyledBottomNavBar = styled("div", {
  width: "100%",
  height: "51px",
  borderTop: "1px solid black",
  position: "fixed",
  bottom: 0,

  left: 0,
  right: 0,
  zIndex: 10000,
  display: "flex",
  backgroundColor: "$wash",
  "@mobile": {
    display: "none",
    // bottom: "30px",
    "& .socials": {
      display: "none",
    },
  },
});

export const SocialBottomBar = ({
  style,
  onBillingPage,
}: {
  style?: Object;
  onBillingPage?: boolean;
}) => (
  <StyledSocialIcons
    onBillingPage={onBillingPage ? true : false}
    className="socials"
    style={{
      position: "absolute",
      backgroundColor: "$wash",
      right: 0,
      bottom: "6px",
      // bottom: 0,
      ...style,
    }}
  >
    <a href="https://www.tiktok.com/@entermoonlight" target="_blank">
      <img className="social-icon" src="/images/socials/tiktok-icon.png"></img>
    </a>
    <a href="https://www.instagram.com/entermoonlight/" target="_blank">
      <img
        className="social-icon"
        src="/images/socials/instagram-icon.png"
      ></img>
    </a>
    <a href="https://www.twitch.tv/entermoonlight" target="_blank">
      <img className="social-icon" src="/images/socials/twitch-icon.png"></img>
    </a>
    <a href="https://twitter.com/entermoonlight" target="_blank">
      <img className="social-icon" src="/images/socials/twitter-icon.png"></img>
    </a>
    <a href="https://www.youtube.com/@entermoonlight" target="_blank">
      <img className="social-icon" src="/images/socials/youtube-icon.png"></img>
    </a>
  </StyledSocialIcons>
);

const StyledSocialIcons = styled("div", {
  zIndex: 3,
  backgroundColor: "$wash",

  paddingLeft: "6px",
  paddingRight: "6px",
  height: "calc(100% - 8px)",
  display: "flex",
  alignItems: "center",
  marginTop: "4px",
  "@mobile": {
    alignItems: "end",
    justifyContent: "center",
    width: "100%",
    bottom: "2px",
    backgroundColor: "transparent",
  },
  "& .social-icon": {
    width: "25px",

    zIndex: 3,
    marginTop: "2px",
    marginLeft: "6px",
    marginRight: "5px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.5,
    },
  },
  variants: {
    onBillingPage: {
      true: {
        "@mobile": {
          height: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: "4px",
          width: "100%",
          position: "fixed !important",
          justifyContent: "center !important",
          backgroundColor: "$wash",
        },
      },
    },
  },
});
