import React, {
  useState,
  useEffect,
  useContext,
  FunctionComponent,
} from "react";
import { useLocation } from "wouter";
import { AxiosError } from "axios";
import { useSnapshot } from "valtio";
import { UserProfile, getUserByUsername } from "~/api/userApi";
import {
  BIO_CHAR_LIMIT,
  BOOKING_MODULE_CHAR_LIMIT,
  DISPLAY_NAME_CHAR_LIMIT,
} from "~/utils/consts";
// import { StyledFormPage } from "../login/SignUp";
import { AuthContext } from "~/utils/useAuth";
import { localState } from "~/state/state";
import { Message } from "~/ui/components/Message";
import { ProfilePicChooser } from "~/ui/components/ProfilePicChooser";
import { SimpleLoadingIndicator } from "../loading/LoadingPage";
import { keyframes, styled } from "~/ui/style/stitches.config";
import { SpinStar } from "~/ui/components/SpinStar";
import {
  ColorEditor,
  accentColors,
  backgroundColors,
  DEFAULT_ACCENT,
  DEFAULT_BACKGROUND,
} from "~/ui/components/ColorEditor";
import { ProPageHeader } from "../../ui/components/ProfileEditButtons";
import { PronounInput } from "../../ui/components/PronounInput";
import {
  SocialMediaIcons,
  SocialMediaInputs,
} from "../../ui/components/SocialMediaInputs";
import {
  BottomTextContent,
  MoreInfo,
  RecommendedBookmarks,
} from "../../ui/components/TextBoxAdder";
import { FloatingButton } from "../../ui/components/FloatingButton";
import { StyledCTA } from "./UserPage";
import { StyledArchProfilePic } from "~/ui/components/ProfilePic";
import {
  BookingModule,
  BookingModuleButton,
  setBookingOverlayVisible,
} from "../marketplace/booking/BookingModule";
import { Marquee } from "~/ui/components/Marquee";
import { MobileOnlyBr } from "../marketplace/DeckMarketplace";
import { StyledFormPage } from "../login/Auth";
import { StyledForm } from "~/ui/components/auth/SignUp";
import { clearToast, sendFriendlyToast } from "~/utils/handleToasts";
import { StyledHelpButton } from "../Home";
import { HelpButton } from "~/ui/components/HelpButton";

type State = {
  uuid: string;
  username: string;
  name: string;
  bio: string;
  profilePic: string;
  isSubmitting: boolean;
  errorMessage: string | null;
  favorites: string[];
  location: string;
  tagline: string;
  pronouns: string;
  languages: string;
  marqueeText: string;
  twitter: string;
  tiktok: string;
  facebook: string;
  instagram: string;
  twitch: string;
  youtube: string;
  website: string;
  email: string;
  recommendedLinks: string[];
  moreInfo: string[];
  backgroundColor: string;
  accentColor: string;
  bookingHeader: string;
};

const initialState: State = {
  uuid: "",
  username: "",
  name: "",
  bio: "",
  profilePic: "",
  isSubmitting: false,
  errorMessage: "",
  favorites: [],
  location: "",
  tagline: "",
  pronouns: "",
  languages: "",
  marqueeText: "",
  instagram: "",
  twitter: "",
  tiktok: "",
  facebook: "",
  twitch: "",
  youtube: "",
  website: "",
  email: "",
  recommendedLinks: [],
  moreInfo: [],
  backgroundColor: "",
  accentColor: "",
  bookingHeader: "",
};

interface ProfileProps {
  username?: string;
}
export interface MoreInfo {
  title: string;
  text: string;
}
const defaultProps: ProfileProps = {
  username: "",
};

export const ProPage = ({ pageUser }: { pageUser: UserProfile }) => {
  const [data, setData] = useState(initialState);
  const { user, updateUser } = useContext(AuthContext);
  let [, setLocation] = useLocation();
  const [savedData, setSavedData] = useState(initialState);
  const [editingProfile, setEditingProfile] = useState(false);
  const [myProfile, setMyProfile] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [tempProfilePic, setTempProfilePic] = useState("");
  const [favoritesUpdated, setFavoritesUpdated] = useState(false);
  const [favorites, setFavorites] = useState<string[]>([]);
  const [familiarPic, setFamiliarPic] = useState("koala.png");
  const { isOnMobile } = useSnapshot(localState);
  const [tableItemPic, setTableItemPic] = useState("aragonite.png");
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(DEFAULT_BACKGROUND);
  const [accentColor, setAccentColor] = useState(DEFAULT_ACCENT);
  const [recommendedBookmarks, setRecommendedBookmarks] = useState<string[]>(
    []
  );

  const [moreInfo, setMoreInfo] = useState<MoreInfo[]>([]);

  useEffect(() => {
    if (backgroundColor.theme === "dark") localState.inDarkMode = true;
    else localState.inDarkMode = false;
    return () => {
      localState.inDarkMode = false;
    };
  }, [backgroundColor]);

  useEffect(() => {
    if (editingProfile) clearToast();
  }, [editingProfile]);

  useEffect(() => {
    setMyProfile(pageUser.username === user?.username);
  }, [pageUser.username, user?.uuid]);

  const handleSocialInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    socialMedia: { name: string; placeholder: string; dontOverWrite?: boolean }
  ) => {
    if (
      !event.target.value.includes(socialMedia.placeholder) &&
      !socialMedia.dontOverWrite
    ) {
      return;
    }
    setData({
      ...data,
      [socialMedia.name]: event.target.value
        .split(socialMedia.placeholder)
        .join(""),
    });
  };
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const resetForm = () => {
    setData(savedData);

    if (!savedData.backgroundColor || !savedData.accentColor) {
      setBackgroundColor(DEFAULT_BACKGROUND);
      setAccentColor(DEFAULT_ACCENT);
      return;
    }

    setBackgroundColor(
      backgroundColors.filter(
        (bgColor) => bgColor.color === savedData.backgroundColor
      )[0]
    );
    setAccentColor(
      accentColors.filter(
        (acColor) => acColor.color === savedData.accentColor
      )[0]
    );
  };

  const handleUpdateProfile = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });

    try {
      const res = await updateUser({
        name: data.name,
        bio: data.bio,
        location: data.location,
        tagline: data.tagline,
        itemPic: tableItemPic,
        animalPic: familiarPic,
        profilePic: data.profilePic,
        pronouns: data.pronouns,
        languages: data.languages,
        marqueeText: data.marqueeText,
        twitter: data.twitter,
        instagram: data.instagram,
        facebook: data.facebook,
        tiktok: data.tiktok,
        youtube: data.youtube,
        twitch: data.twitch,
        website: data.website,
        recommendedLinks: recommendedBookmarks,
        moreInfo: moreInfo.map((info) => {
          return `${info.title}::${info.text}`;
        }),
        backgroundColor: backgroundColor.color,
        accentColor: accentColor.color,
        bookingHeader: data.bookingHeader,
      });
      setEditingProfile(false);
      setProfilePic(tempProfilePic);
      localState.editedProfilePic = true;
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: null,
      });
      setSavedData({
        ...data,
        isSubmitting: false,
        errorMessage: null,
      });
      // Profile updated successfully
      sendFriendlyToast(1041);
    } catch (error) {
      let errorMessage = null;
      if (error instanceof AxiosError) {
        errorMessage = error.message;
      }
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: errorMessage,
      });
    }
  };

  useEffect(() => {
    setData({
      ...data,
      uuid: pageUser.uuid,
      username: pageUser.username,
      name: pageUser.name,
      bio: pageUser.bio,
      email: pageUser.email,
      profilePic: pageUser.profilePic,
      favorites: pageUser.favorites,
      location: pageUser.location,
      tagline: pageUser.tagline,
      pronouns: pageUser.pronouns,
      languages: pageUser.languages,
      marqueeText: pageUser.marqueeText,
      twitter: pageUser.twitter,
      instagram: pageUser.instagram,
      facebook: pageUser.facebook,
      tiktok: pageUser.tiktok,
      youtube: pageUser.youtube,
      twitch: pageUser.twitch,
      website: pageUser.website,
      backgroundColor: pageUser.backgroundColor,
      accentColor: pageUser.accentColor,
      bookingHeader: pageUser.bookingHeader,
    });
    setSavedData({
      ...data,
      uuid: pageUser.uuid,
      username: pageUser.username,
      name: pageUser.name,
      bio: pageUser.bio,
      email: pageUser.email,
      profilePic: pageUser.profilePic,
      favorites: pageUser.favorites,
      location: pageUser.location,
      tagline: pageUser.tagline,
      pronouns: pageUser.pronouns,
      languages: pageUser.languages,
      marqueeText: pageUser.marqueeText,
      twitter: pageUser.twitter,
      instagram: pageUser.instagram,
      facebook: pageUser.facebook,
      tiktok: pageUser.tiktok,
      youtube: pageUser.youtube,
      twitch: pageUser.twitch,
      website: pageUser.website,
      backgroundColor: pageUser.backgroundColor,
      accentColor: pageUser.accentColor,
      bookingHeader: pageUser.bookingHeader,
    });
    setProfilePic(pageUser.profilePic);
    setTempProfilePic(pageUser.profilePic);
    setFamiliarPic(pageUser.animalPic ? pageUser.animalPic : familiarPic);
    setTableItemPic(pageUser.itemPic ? pageUser.itemPic : tableItemPic);
    setRecommendedBookmarks(pageUser.recommendedLinks);
    setMoreInfo(
      pageUser.moreInfo.map((infoText) => {
        const splitText = infoText.split("::");
        return {
          title: splitText[0],
          text: splitText[1],
        };
      })
    );
    if (!pageUser.backgroundColor || !pageUser.accentColor) {
      setBackgroundColor(DEFAULT_BACKGROUND);
      setAccentColor(DEFAULT_ACCENT);
    } else {
      setBackgroundColor(
        backgroundColors.filter(
          (backgroundColor) =>
            backgroundColor.color === pageUser.backgroundColor
        )[0]
      );
      setAccentColor(
        accentColors.filter(
          (accentColor) => accentColor.color === pageUser.accentColor
        )[0]
      );
    }

    setFavorites(pageUser.favorites);
    if (favoritesUpdated) setFavoritesUpdated(false);
    setProfileLoaded(true);

    setProfileLoaded(true);
  }, [pageUser.username, favoritesUpdated]);

  const [linkCopied, setLinkCopied] = useState(false);
  useEffect(() => {
    if (linkCopied) window.setTimeout(() => setLinkCopied(false), 3000);
  }, [linkCopied]);

  return (
    <>
      {pageUser && <BookingModule pro={pageUser} accentColor={accentColor} />}
      {!profileLoaded && (
        <div className="ldg">
          <SimpleLoadingIndicator />{" "}
        </div>
      )}

      <StyledProPage
        id="profile-page"
        loaded={profileLoaded}
        myProfile={myProfile}
        editing={editingProfile}
        Theme={backgroundColor.theme === "dark" ? "dark" : "light"}
        style={{
          backgroundColor: backgroundColor.color,
          overflowX: "hidden",
        }}
      >
        {!user && (
          <StyledCTA
            className="cta"
            style={{
              zIndex: "1",
              position: "absolute",
              top: "80px",
            }}
          >
            <img
              className="star"
              src="/images/thestar.png"
              style={{
                filter: backgroundColor.theme === "dark" ? "invert(1)" : "",
              }}
            />
            <span className="cta-container">
              Moonlight is a new social space for tarot.
              <span
                className="cta-link"
                onClick={() => setLocation("/signup")}
                style={{
                  marginLeft: "4px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Join us!
              </span>
            </span>
          </StyledCTA>
        )}

        {!editingProfile && (
          <FloatingButton
            color={accentColor.color}
            onClick={() => {
              setBookingOverlayVisible(true);
            }}
          />
        )}

        {user && (
          <div
            style={{
              position: "absolute",
              top: "50px",
              left: 0,
              right: 0,
              margin: "auto",
              zIndex: 999,
            }}
          >
            <Message />
          </div>
        )}
        <StyledProForm>
          <form
            className="pro-form"
            style={{ marginTop: "60px" }}
            onSubmit={handleUpdateProfile}
          >
            <ProPageHeader
              editingProfile={editingProfile}
              linkCopied={linkCopied}
              setLinkCopied={setLinkCopied}
              backgroundTheme={backgroundColor.theme}
              backgroundColor={backgroundColor.color}
              data={data}
              setFavoritesUpdated={setFavoritesUpdated}
              favorites={favorites}
              setEditingProfile={setEditingProfile}
              resetForm={resetForm}
            />

            <div className="top-row">
              <div
                className="image-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="image-inner-container"
                  style={{ position: "relative" }}
                >
                  <StyledArchProfilePic
                    css={{ $$size: "100%" }}
                    src={"/images/profile-pics/" + data.profilePic}
                  ></StyledArchProfilePic>
                  <SpinStar
                    style={{
                      position: "absolute",
                      right: "-30px",
                      bottom: "-30px",
                    }}
                    color={accentColor.color}
                    borderColor={"black"}
                  />
                </div>
                {editingProfile && (
                  <div
                    id="desktop-pro-pic-editor"
                    style={{
                      display: "flex",
                      marginTop: "30px",
                      marginLeft: "60px",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ProfilePicChooser
                      isEditing={editingProfile}
                      setProfilePic={setFamiliarPic}
                      profilePic={familiarPic}
                      isMyProfile={true}
                      onProPage={true}
                      setProfileLoaded={() => {}}
                    />
                    <div style={{ width: "20px" }}></div>
                    <ProfilePicChooser
                      useTableItems={true}
                      isEditing={editingProfile}
                      setProfilePic={setTableItemPic}
                      profilePic={tableItemPic}
                      onProPage={true}
                      isMyProfile={true}
                      setProfileLoaded={() => {}}
                    />
                  </div>
                )}
              </div>

              <div className="inputs" style={{ marginRight: "20px" }}>
                {editingProfile && (
                  <ColorEditor
                    setBackgroundColor={setBackgroundColor}
                    setAccentColor={setAccentColor}
                    theme={backgroundColor.theme === "dark" ? "dark" : "light"}
                    backgroundColor={backgroundColor.color}
                    accentColor={accentColor.color}
                  />
                )}
                <div className="input-container" style={{}}>
                  <div
                    className="name text-name"
                    style={{ marginLeft: "12px" }}
                  >
                    {data.name}
                  </div>
                  <input
                    className="name show-display-error"
                    type="text"
                    name="name"
                    placeholder="Display name"
                    spellCheck={false}
                    maxLength={DISPLAY_NAME_CHAR_LIMIT}
                    required
                    onInvalid={(e) => e.preventDefault()}
                    value={data.name}
                    onChange={handleInputChange}
                  />
                  <p
                    style={{ marginLeft: "4px", marginBottom: "12px" }}
                    className="error-message"
                  >
                    Please enter a display name
                  </p>
                </div>
                {editingProfile && (
                  <>
                    <div className="input-container">
                      <input
                        id="input-tagline"
                        type="text"
                        name="tagline"
                        spellCheck={false}
                        placeholder="Tagline"
                        style={{ paddingTop: isOnMobile ? "16px" : "20px" }}
                        maxLength={32}
                        onInvalid={(e) => e.preventDefault()}
                        value={data.tagline}
                        onChange={handleInputChange}
                      />
                    </div>
                    <PronounInput
                      data={data}
                      theme={
                        backgroundColor.theme === "dark" ? "dark" : "light"
                      }
                      handleInputChange={handleInputChange}
                    />
                  </>
                )}
                {!editingProfile && (data.tagline || data.pronouns) && (
                  <div id="input-tagline">{`${data.tagline} ${
                    data.pronouns && `(${data.pronouns})`
                  }`}</div>
                )}

                {editingProfile && (
                  <>
                    <div className="input-container">
                      <input
                        id="location-input"
                        className="with-icon"
                        type="text"
                        name="location"
                        placeholder="Location"
                        maxLength={DISPLAY_NAME_CHAR_LIMIT}
                        onInvalid={(e) => e.preventDefault()}
                        value={data.location}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="input-container">
                      <input
                        id="input-languages"
                        type="text"
                        name="languages"
                        className="with-icon"
                        placeholder="Languages spoken"
                        maxLength={DISPLAY_NAME_CHAR_LIMIT * 2}
                        onInvalid={(e) => e.preventDefault()}
                        value={data.languages}
                        onChange={handleInputChange}
                      />
                    </div>
                  </>
                )}
                {!editingProfile && (
                  <div
                    className="language-location-container"
                    style={{
                      textAlign: "left",
                      marginLeft: "8px",
                      marginBottom: "12px",
                      fontSize: "13px",
                      display: "flex",
                    }}
                  >
                    {data.location && data.location.trim().length > 0 && (
                      <div className="lang-loc">
                        <img
                          src={
                            backgroundColor.theme === "dark"
                              ? "/images/location-dark.svg"
                              : `/images/location.svg`
                          }
                        ></img>
                        {data.location}
                      </div>
                    )}
                    {data.languages && (
                      <div className="lang-loc">
                        <img
                          src={
                            backgroundColor.theme === "dark"
                              ? "/images/language-dark.svg"
                              : `/images/language.svg`
                          }
                        ></img>
                        {data.languages}
                      </div>
                    )}
                  </div>
                )}
                {!editingProfile && (
                  <SocialMediaIcons
                    data={data}
                    theme={backgroundColor.theme === "dark" ? "dark" : "light"}
                  />
                )}
                <div
                  id="bio-input"
                  className="bio-input-text"
                  style={{
                    marginLeft: "12px",
                  }}
                >
                  {data.bio}
                </div>
                <textarea
                  id="bio-input"
                  name="bio"
                  spellCheck={true}
                  style={{ lineHeight: "1.3" }}
                  placeholder={
                    myProfile ? "Your bio – Tell us about yourself!" : ""
                  }
                  maxLength={BIO_CHAR_LIMIT}
                  value={data.bio}
                  onChange={handleInputChange}
                />
                {editingProfile && (
                  <div className="input-container">
                    <input
                      spellCheck={false}
                      id="input-marquee"
                      type="text"
                      name="marqueeText"
                      placeholder="Scrolling welcome text"
                      maxLength={DISPLAY_NAME_CHAR_LIMIT}
                      onInvalid={(e) => e.preventDefault()}
                      value={data.marqueeText}
                      onChange={handleInputChange}
                    />
                  </div>
                )}

                {editingProfile && (
                  <div
                    id="mobile-pro-pic-editor"
                    style={{
                      display: "flex",
                      marginTop: "30px",
                      marginLeft: "30px",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ProfilePicChooser
                      isEditing={editingProfile}
                      setProfilePic={setFamiliarPic}
                      profilePic={familiarPic}
                      isMyProfile={true}
                      onProPage={true}
                      setProfileLoaded={() => {}}
                    />
                    <div style={{ width: "50px" }}></div>
                    <ProfilePicChooser
                      useTableItems={true}
                      isEditing={editingProfile}
                      setProfilePic={setTableItemPic}
                      profilePic={tableItemPic}
                      onProPage={true}
                      isMyProfile={true}
                      setProfileLoaded={() => {}}
                    />
                  </div>
                )}

                {editingProfile && (
                  <>
                    <SocialMediaInputs
                      theme={
                        backgroundColor.theme === "dark" ? "dark" : "light"
                      }
                      handleSocialInputChange={handleSocialInputChange}
                      data={data}
                    ></SocialMediaInputs>
                    <RecommendedBookmarks
                      theme={
                        backgroundColor.theme === "dark" ? "dark" : "light"
                      }
                      setRecommendedBookmarks={setRecommendedBookmarks}
                      recommendedBookmarks={recommendedBookmarks}
                      isSubmitting={data.isSubmitting}
                    />
                    <MoreInfo
                      theme={
                        backgroundColor.theme === "dark" ? "dark" : "light"
                      }
                      setMoreInfo={setMoreInfo}
                      moreInfo={moreInfo}
                      isSubmitting={data.isSubmitting}
                    />
                  </>
                )}
              </div>
              <div
                className="col-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <div
                  className="booking-module"
                  style={{
                    backgroundColor: accentColor.color,
                    border: `1px solid black`,
                    boxShadow: `0px 3px 0px black`,
                  }}
                >
                  {pageUser && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <BookingModuleButton
                        accentColor={accentColor}
                        pro={pageUser}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>

          {!editingProfile && (
            <Marquee
              page={"profile"}
              text={`<div class='marquee-text'>${
                data.marqueeText ? data.marqueeText : "welcome to my page"
              }</div>`}
              style={{
                borderTop: `1px solid ${
                  backgroundColor.theme === "dark" ? "white" : "black"
                }`,
                borderBottom: `1px solid ${
                  backgroundColor.theme === "dark" ? "white" : "black"
                }`,
              }}
            />
          )}
          <div
            className="bottom-area"
            style={{ display: "flex", alignItems: "start", width: "100%" }}
          >
            {!editingProfile && (
              <div
                className="profile-pic-bottom-container"
                style={{
                  display: "flex",
                  marginTop: "30px",
                  marginLeft: "30px",
                  width: "33.3333%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ProfilePicChooser
                  isEditing={editingProfile}
                  setProfilePic={setFamiliarPic}
                  profilePic={familiarPic}
                  isMyProfile={true}
                  onProPage={true}
                  setProfileLoaded={() => {}}
                />
                <ProfilePicChooser
                  useTableItems={true}
                  isEditing={editingProfile}
                  setProfilePic={setTableItemPic}
                  profilePic={tableItemPic}
                  onProPage={true}
                  isMyProfile={true}
                  setProfileLoaded={() => {}}
                />
              </div>
            )}
            {!editingProfile && (
              <BottomTextContent
                recommendedBookmarks={recommendedBookmarks}
                moreInfo={moreInfo}
                theme={backgroundColor.theme === "dark" ? "dark" : "light"}
              />
            )}
          </div>

          <div
            className="booking-module mobile-booking-module"
            style={{
              backgroundColor: accentColor.color,
              border: `1px solid black`,
              boxShadow: `0px 3px 0px black`,
            }}
          >
            {pageUser && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <BookingModuleButton accentColor={accentColor} pro={pageUser} />
              </div>
            )}
          </div>
        </StyledProForm>
      </StyledProPage>
      <HelpButton
        isOnProPage={true}
        isOnBillingPage={true}
        text={"?"}
      ></HelpButton>
    </>
  );
};

const spin = keyframes({
  "0%": { transform: "rotate(0deg)" },
  "50%": { transform: "rotate(180deg)" },
  "100%": { transform: "rotate(360deg)" },
});

const fadein = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const marquee = keyframes({
  "0%": {
    transform: "translateX(-175px) ",
  },

  "100%": {
    transform: "translateX(0) ",
  },
});

const StyledProForm = styled(StyledForm, {
  "& > *": {
    transition: "opacity 1s",
  },

  width: "100%",
  backgroundColor: "transparent",
  border: "none",
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "start",
  maxWidth: "unset",
});

const StyledProPage = styled(StyledFormPage, {
  paddingTop: "60px",
  height: "100%",
  width: "100%",
  overflow: "auto",
  position: "fixed",

  "#fadein1": {
    animation: `${fadein} 0.6s ease-in 1`,
  },
  "& .booking-module": {
    width: "80%",
    borderRadius: "40px",
    aspectRatio: "1 / 1 !important",
    "&.mobile-booking-module": {
      display: "none",
    },
    "@mobile": {
      display: "none",
      "&.mobile-booking-module": {
        display: "block",
        marginLeft: "10%",
        "& button.book-me-button": {
          width: "150px !important",
          height: "50px !important",
        },
      },
      width: "80%",
      marginTop: "30px",
      borderRadius: "24px",
    },
  },

  "& .top-row": {
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
  },
  "& .col-3": {
    width: "33.33333333%",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "8px",
  },
  "& .language-location-container": {
    "@mobile": {
      flexDirection: "column",
    },
    "& .lang-loc": {
      display: "flex",
      alignItems: "center",
      marginRight: "18px",
      "@mobile": {
        marginBottom: "6px",
      },
    },
    "& img": {
      width: "18px",
      marginRight: "6px",
    },
  },
  "& .image-container": {
    width: "33.33333%",
    display: "flex",
    flexShrink: 0,
    alignSelf: "start",
    justifyContent: "center",
    "& .image-inner-container": {
      width: "80%",
      flexShrink: 0,

      "& img": {
        borderRadius: "50% 50% 12px 12px",
        width: "100%",
        border: "1px solid black",
        objectFit: "cover",
        flexShrink: 0,
        alignSelf: "center",
      },
    },
  },

  "& .cta": {
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    marginTop: "-6px",
    "& span": {
      transition: "all 0.2s",
    },
    "& .cta-link:hover": {
      opacity: 0.5,
    },
  },
  "& .star": {
    width: "36px",
    marginRight: "10px",
    animation: `${spin} 8s linear infinite`,
  },

  "& .pro-form": {
    width: "100% !important",
    display: "flex",
    flexDirection: "row !important",
  },
  "& .inputs": {
    width: "33%",
  },
  "& .name:not(:placeholder-shown)": {
    fontFamily: "GorgeousBaby",
    fontSize: "40px !important",
    "@smaller": {
      fontSize: "48px !important",
    },
  },
  "& #input-tagline": {
    fontFamily: "KeplerLightCondensed",
    fontSize: "26px",
    textTransform: "uppercase",
    textAlign: "left",
  },
  "& #input-marquee": {
    textTransform: "uppercase",
  },
  "& input.name": {
    height: "60px !important",
    lineHeight: "60px",
    verticalAlign: "middle",
    paddingLeft: "0 !important",
    textIndent: "12px",
  },
  "& .text-name": {
    textAlign: "left",
    marginLeft: "16px",
    marginBottom: "5px",
    overflowWrap: "break-word",
    lineHeight: "44px",
    "@smaller": {
      marginLeft: "0",
      lineHeight: "55px",
    },
  },

  "& .help-text": {
    fontSize: "10px",
    "@smaller": {
      fontSize: "13px",
    },
    fontStyle: "italic",
    textAlign: "left",
    marginTop: "5px",
    opacity: 0,
    transition: "opacity 0.2s",
  },
  "& input, & textarea, & .textarea": {
    width: "100% !important",
    height: "100px",
    padding: "12px",
    border: "1px solid rgba(0, 0, 0, 0.2) !important",
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    transition: "backgroundColor 0.2s",
  },
  "& .bio-input-text": {
    fontSize: "13px",
    lineHeight: "1.3",

    textAlign: "left",
    overflowWrap: "break-word",
    marginLeft: "16px",
    "@smaller": {
      fontSize: "14px",
    },
  },
  "& textarea, & .textarea": {
    fontSize: "13px",
    "@smaller": {
      fontSize: "14px",
    },
    resize: "none",
    height: "140px",
  },
  "& input:focus, & textarea:focus, & .textarea:focus": {
    outline: "none !important",
    borderColor: "black !important",
  },
  "& .input-headline": {
    marginTop: "36px",
    fontFamily: "KeplerLightCondensed",
    fontSize: "30px",
    textAlign: "left",
    marginBottom: "6px",
  },
  "& input.with-icon": {
    paddingLeft: "30px !important",
    backgroundPosition: "8px calc(50% - 1px)",
    backgroundRepeat: "no-repeat",
  },
  "& input#location-input": {
    backgroundImage: "url(/images/location.svg)",
    "@smaller": {
      fontSize: "15px",
    },
  },

  "& input#input-languages": {
    backgroundImage: "url(/images/language.svg)",
    backgroundSize: "17px",
    backgroundPosition: "10px calc(50%)",
    paddingLeft: "32px !important",
    "@smaller": {
      fontSize: "14px",
    },
  },

  "& .edit-buttons": {
    display: "flex",
    width: "80%",
    justifyContent: "flex-end",
    "@mobile": {
      width: "100%",
    },
  },

  "& #mobile-pro-pic-editor": {
    display: "none !important",
  },
  "& #desktop-pro-pic-editor": {
    display: "flex !important",
  },

  "@smaller": {
    overflowX: "hidden !important",
    paddingTop: "36px",
    "& #mobile-pro-pic-editor": {
      display: "flex !important",
    },
    "& #desktop-pro-pic-editor": {
      display: "none !important",
    },
    "& .marquee": {
      marginLeft: "-10%",
      marginTop: "30px",
      width: "120%",
    },

    "& .top-row": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "& .image-container": {
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
      },
      "@mobile": {
        "& .image-container": {
          width: "90%",
        },
      },
      "& .inputs": {
        width: "80%",
        marginTop: "36px",
        textAlign: "left",
      },
    },

    "& .bottom-area": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center !important",
      "& .profile-pic-bottom-container": {
        width: "100% !important",
        marginLeft: "50px !important",
      },
    },
    display: "flex",
    justifyContent: "start",
    overflowY: "scroll",
    paddingBottom: "30px",
    "& .extra": {
      display: "none",
    },
    "& .form-container": {
      width: "90%",
      "& .pro-form": {
        flexDirection: "column !important",
        alignItems: "center",
        width: "100%",
        "& .inputs": {
          maxWidth: "80%",
        },
      },
    },
  },

  compoundVariants: [
    {
      Theme: "dark",
      editing: true,
      css: {
        "& input:not(.booking-module-header), & textarea, & .textarea": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          border: "1px solid rgba(255, 255, 255, 0.4) !important",
        },
      },
    },
  ],
  variants: {
    Theme: {
      light: {
        "& input::placeholder, & textarea::placeholder, & .textarea::placeholder, & input.empty-true":
          {
            color: "#777",
          },
      },
      dark: {
        color: "white",
        "& input#input-languages": {
          backgroundImage: "url(/images/language-dark.svg)",
        },
        "& input#location-input": {
          backgroundImage: "url(/images/location-dark.svg)",
        },
        "& input:focus, & textarea:focus, & .textarea:focus": {
          outline: "none !important",
          borderColor: "white !important",
        },
        "& .marquee": {
          borderTop: "1px solid white",
          borderBottom: "1px solid white",
        },

        "& .image-container .image-inner-container img": {
          border: "1px solid white",
        },
        "& input::placeholder, & textarea::placeholder, & .textarea::placeholder, & input.empty-true":
          {
            color: "#ddd",
          },
      },
    },
    loaded: {
      true: {
        "& .ldg": {
          display: "none",
        },
      },
      false: {
        ".form-container > *": {
          opacity: "0 !important",
        },
        "& .ldg": {
          opacity: "0.3 !important",
          position: "absolute",
          left: 0,
          right: 0,
          margin: "auto",
          top: 0,
          bottom: 0,
        },
      },
    },
    editing: {
      true: {
        "& .help-text": {
          opacity: 1,
        },
        "& .text-name": {
          display: "none",
        },
        "& .bio-input-text": {
          display: "none",
        },
        "& .top-row": {
          alignItems: "start",
          "@smaller": {
            alignItems: "center",
          },
        },
        "& .booking-module.mobile": {
          display: "none",
        },
      },
      false: {
        "& input, & textarea, & .textarea": {
          pointerEvents: "none",
          borderColor: "transparent !important",
          backgroundColor: "transparent",
        },

        "& input.booking-module-header": {
          display: "none",
        },

        "& input#location-input": {
          marginTop: "-12px",
          marginBottom: 0,
        },
        "& #input-tagline": {
          marginLeft: "12px",
          marginTop: "8px",
          marginBottom: "8px",
        },
        "& input#input-tagline": {
          marginTop: "-6px",
          marginBottom: 0,
        },
        "& textarea#bio-input": {
          display: "none",
        },
        "& input.name": {
          display: "none",
        },
      },
    },
    myProfile: {
      true: {
        "& .edit-buttons .not-myprofile": {
          display: "none !important",
        },
      },
      false: {
        "& .edit-buttons .myprofile": {
          display: "none !important",
        },
      },
    },
  },
});
