import { styled } from "@stitches/react";
import { useEffect } from "react";
import { useLocation } from "wouter";
import { localState } from "~/state/state";
import { Button } from "~/ui/components/Button";

export const PageNotFound = () => {
  let [_, setLocation] = useLocation();

  return (
    <StyledErrorPage>
      <div className="message-container">
        <img className="page" src="/images/pagenotfound.png"></img>
        <div className="title">404 • Page not found</div>
        <div className="subtitle">
          Oops! We couldn’t find the link you’re looking for.
        </div>
        <Button onClick={() => setLocation("/")}>Return home</Button>
      </div>
    </StyledErrorPage>
  );
};

export const StyledErrorPage = styled("div", {
  height: "100vh",
  width: "100vw",
  backgroundColor: "$wash",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& .message-container": {
    textAlign: "center",
    maxWidth: "80%",
    "& img": {
      borderRadius: "50%",
      width: "184px",
      height: "184px",
    },
    "& img.page": {
      borderRadius: 0,
      width: "unset",
      height: "304px",
    },
    "& .title": {
      fontFamily: "GorgeousBaby",
      fontSize: "40px",
      marginTop: "20px",
    },
    "& .subtitle": {
      fontSize: "14px",
      marginTop: "8px",
      marginBottom: "20px",
    },
  },
});
