import { keyframes, styled } from "~/ui/style/stitches.config";
import { useEffect, useState } from "react";
import allProfilePics from "src/preloading/profilePicsToPreload.json";
import allTableItems from "src/preloading/tableItemsToPreload.json";
import { StyledProfilePic } from "./ProfilePic";
import { ImagePreloader } from "~/preloading/Preloader";

const ANIMATION_TIME = 1000;
export const ProfilePicChooser = ({
  isEditing,
  setProfilePic,
  profilePic,
  isMyProfile,
  setProfileLoaded,
  useTableItems,
  onProPage,
}: {
  isEditing: boolean;
  setProfilePic: Function;
  profilePic: string;
  isMyProfile: boolean;
  setProfileLoaded: Function;
  useTableItems?: boolean;
  onProPage?: boolean;
}) => {
  const [historyIdx, setHistoryIdx] = useState(0);
  const [isAnimating, setIsAnimating] = useState<false | "forward" | "back">(
    false
  );

  const [shuffledProfilePics, setShuffledProfilePics] = useState<string[]>([]);
  const [filesToLoad, setFilesToLoad] = useState<string[]>([]);
  const allPics = useTableItems ? allTableItems : allProfilePics;

  const myProfileIconsToLoad = [
    "/images/room/menu-close.png",
    "/images/room/copy-link-icon.svg",
    "/images/edit.svg",
  ];

  const otherProfileIconsToLoad = [
    "/images/room/menu-close.png",
    "/images/room/copy-link-icon.svg",
    "/images/room/heart.svg",
    "/images/room/heart-full.svg",
  ];

  useEffect(() => {
    if (!profilePic) return;
    const newFilesToLoad = isMyProfile
      ? [
          ...allPics.map(
            (pic) =>
              `/images/${useTableItems ? "table-items" : "profile-pics"}/${pic}`
          ),
          ...myProfileIconsToLoad,
        ]
      : [
          `/images/${
            useTableItems ? "table-items" : "profile-pics"
          }/${profilePic}`,
          ...otherProfileIconsToLoad,
        ];
    setFilesToLoad(newFilesToLoad);
  }, [isMyProfile, profilePic]);

  const [prevProfilePic, setPrevProfilePic] = useState<string | null>(null);

  useEffect(() => {
    if (prevProfilePic !== null) return;
    setPrevProfilePic(profilePic);
    setHistoryIdx(0);
    let newShuffledProfilePics = allPics.sort((a, b) => 0.5 - Math.random());
    const initialPicIdx = newShuffledProfilePics.findIndex(
      (proPic) => proPic === profilePic
    );
    if (initialPicIdx >= 0) {
      newShuffledProfilePics.splice(initialPicIdx, 1);
    }
    setShuffledProfilePics([
      profilePic ? profilePic : "",
      ...newShuffledProfilePics,
    ]);
  }, [profilePic]);

  const goForward = () => {
    setIsAnimating("forward");
  };

  const goBack = () => {
    setIsAnimating("back");
  };

  useEffect(() => {
    if (shuffledProfilePics[historyIdx]) {
      setProfilePic(shuffledProfilePics[historyIdx]);
    }
  }, [historyIdx]);

  useEffect(() => {
    if (isAnimating)
      window.setTimeout(() => setIsAnimating(false), ANIMATION_TIME);
    if (isAnimating === "forward") {
      window.setTimeout(
        () =>
          setHistoryIdx(historyIdx < allPics.length - 1 ? historyIdx + 1 : 0),
        ANIMATION_TIME / 2
      );
    } else if (isAnimating === "back") {
      window.setTimeout(
        () =>
          setHistoryIdx(historyIdx > 0 ? historyIdx - 1 : allPics.length - 1),
        ANIMATION_TIME / 2
      );
    }
  }, [isAnimating]);

  return (
    <StyledProfilePicChooser
      useTableItems={useTableItems}
      onProPage={onProPage}
      editing={isEditing}
      animating={
        isAnimating === "forward" || isAnimating === "back" ? true : false
      }
    >
      {filesToLoad.length > 0 && (
        <ImagePreloader
          filesToLoad={filesToLoad}
          setIsLoaded={setProfileLoaded}
        ></ImagePreloader>
      )}

      {isMyProfile ? (
        shuffledProfilePics.map((img, i) => (
          <StyledProfilePic
            css={{ $$size: "210px", $$padding: 0 }}
            key={i}
            className={`profile-pic active-${
              (!isEditing && img === profilePic) ||
              (isEditing && historyIdx === i)
            }`}
            src={`/images/${
              useTableItems ? "table-items" : "profile-pics"
            }/${img}`}
          ></StyledProfilePic>
        ))
      ) : (
        <StyledProfilePic
          css={{ $$size: "210px", $$padding: 0 }}
          className={`profile-pic active-true`}
          src={`/images/${
            useTableItems ? "table-items" : "profile-pics"
          }/${profilePic}`}
        ></StyledProfilePic>
      )}

      {isEditing && (
        <>
          <img
            onClick={(e) => {
              e.preventDefault();
              goBack();
            }}
            className="image-chooser left"
            src={"/images/room/menu-close.png"}
          ></img>

          <img
            onClick={(e) => {
              e.preventDefault();
              goForward();
            }}
            className="image-chooser right"
            src={"/images/room/menu-close.png"}
          ></img>
        </>
      )}
    </StyledProfilePicChooser>
  );
};

const fadeInOut = keyframes({
  "0%": { opacity: 1 },
  "50%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const StyledProfilePicChooser = styled("div", {
  width: "240px",
  height: "240px",
  position: "relative",
  marginTop: "-24px",
  marginRight: "26px",
  marginLeft: "-26px",
  "@mobile": {
    display: "flex",
    justifyContent: "center",
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
  },

  "& img": {
    WebkitUserSelect: "none !important",
    WebkitUserDrag: "none !important",
  },
  "& img.profile-pic": {
    display: "block",
    alignSelf: "start",
    borderRadius: "50%",
    padding: "12px",
    transition: "opacity 1s ease-out",
    opacity: 1,
    position: "absolute",
    "&.active-false": {
      opacity: 0,
    },
  },
  "& .image-chooser": {
    width: "30px",
    height: "30px",
    padding: "4px",
    backgroundColor: "$washA800",
    borderRadius: "50%",
    position: "absolute",
    bottom: 0,
    top: "-24px",
    margin: "auto",
    cursor: "pointer",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: "$wash",
    },
    "&.right": {
      right: "6px",
    },
    "&.left": {
      transform: "rotate(180deg)",
      left: 0,
    },
  },
  variants: {
    onProPage: {
      true: {
        width: "150px",
        height: "150px",
        "& .image-chooser": {
          top: "0px",
          marginLeft: "5px",
        },
        "& img.profile-pic": {
          width: "150px !important",
          height: "150px !important",
        },

        "@smaller": {
          flexShrink: "0",
          alignSelf: "center",
          "& .image-chooser": {
            // right: "-20px !important",
          },
        },
      },
      false: {
        "@smaller": {
          marginRight: 0,
          marginBottom: "15px",
          marginTop: "-40px",
          width: "180px",
          height: "180px",
          "& img.profile-pic": {
            transform: "scale(0.8) !important",
          },
          "& .image-chooser": {
            top: "24px !important",
            "&.right": {
              right: "-32px !important",
            },
          },
        },
      },
    },
    useTableItems: {
      true: {
        width: "180px !important",
        height: "180px !important",
        "& img.profile-pic": {
          borderRadius: 0,
          width: "180px !important",
          height: "180px !important",
        },
      },
    },
    animating: {
      true: {
        "& .image-chooser": {
          animation: `${fadeInOut} 1s ease-in-out`,
          pointerEvents: "none",
        },
      },
      false: {},
    },
    editing: {
      true: {},
      false: {
        "& img.profile-pic.active-true": {
          zIndex: 1,
          opacity: 1,
          transition: "none",
        },
      },
    },
  },
});
