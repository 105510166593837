import { styled } from "@stitches/react";
import { useLocation } from "wouter";
import { Button } from "~/ui/components/Button";
import { StyledErrorPage } from "./PageNotFound";
import { handleCreateRoom } from "~/api/roomApi";

export const RoomNotFound = () => {
  let [_, setLocation] = useLocation();

  return (
    <StyledErrorPage>
      <div className="message-container">
        <img className="header" src="/images/candle-monochrome.gif"></img>
        <div className="title">Tarot rooms are ephemeral</div>
        <div className="subtitle">
          Generate a link to a new Moonlight session.
        </div>
        <Button onClick={handleCreateRoom}>Create new room</Button>
      </div>
    </StyledErrorPage>
  );
};
