import { CheckIcon } from "@radix-ui/react-icons";
import { Button } from "../components/Button";
import { useMutateStorage, useStorage } from "../../state/liveblocks.config";
import { Spread } from "../../types/room/types";

import pastPresentFuture from "../../assets/spreads/past-present-future.json";
import mindBodySpirit from "../../assets/spreads/mind-body-spirit.json";

import personaObstacleSolution from "../../assets/spreads/persona-obstacle-solution.json";
import noneSpread from "../../assets/spreads/none.json";
import celticCross from "../../assets/spreads/celtic-cross.json";
import { StyledLeftListItem, StyledLeftMenu } from "./StyledMenus";

import { useState } from "react";

export function SpreadMenu({
  isOpen,
  setIsOpen,
  onSpreadChanged,
}: {
  isOpen: boolean;
  setIsOpen: Function;
  onSpreadChanged: Function;
}) {
  const updateStorage = useMutateStorage();
  const currentSpread = useStorage((root) => root.spread);
  const theme = useStorage((root) => root.theme);
  const [showSpreadImporter, setShowSpreadImporter] = useState(false);

  const allSpreads: Spread[] = [
    noneSpread as unknown as Spread,
    pastPresentFuture as unknown as Spread,
    personaObstacleSolution as unknown as Spread,
    mindBodySpirit as unknown as Spread,
    celticCross as unknown as Spread,
  ];

  const deckSelectMenu = (
    <StyledLeftMenu id={"shuffle-deck"} Theme={theme}>
      <Button
        Theme={theme}
        role="menuitem"
        className="arrow-button"
        style={{}}
        transparent
        onClick={() => {
          setIsOpen(false);
        }}
      >
        &larr;
      </Button>

      {allSpreads.map((spread, i) => (
        <StyledLeftListItem key={i} Theme={theme}>
          <Button
            Theme={theme}
            style={{ fontSize: "12px" }}
            transparent
            transparentHover
            menuOption
            onClick={() => {
              updateStorage((storage) => {
                storage.set("spread", spread);
              });
              setIsOpen(false);
              onSpreadChanged();
            }}
          >
            {spread.name}
            <CheckIcon
              style={{
                opacity: spread.name === currentSpread.name ? 1 : 0,
                flexShrink: 0,
                marginRight: "-12px",
              }}
            />
          </Button>
        </StyledLeftListItem>
      ))}
    </StyledLeftMenu>
  );

  return (
    <div>
      {isOpen ? (
        deckSelectMenu
      ) : (
        <Button
          Theme={theme}
          className="nav-button nav-bar-button"
          style={{
            backgroundColor:
              theme === "light"
                ? "hsla(0,0%, 92%, 0.8)"
                : "hsla(0, 0%, 10%, 0.7)",
            padding: "4px 6px 4px 4px",
            marginLeft: "-1px",
            boxShadow: "none",
            borderRadius: "6px",
          }}
          smallTransparent
          onClick={() => {
            setIsOpen(true);
          }}
        >
          + Select spread
        </Button>
      )}
    </div>
  );
}
