import { createClient, LiveList, LiveObject } from "@liveblocks/client";
import { createRoomContext } from "@liveblocks/react";
import { Deck,  RoomState, RoomTheme, Shape, SideMenu, Spread, SpreadCard } from "../types/room/types";
import defaultSpread from "../assets/spreads/persona-obstacle-solution.json";
import defaultDeck from "../assets/decks/rider-waite.json";
import { Vec2d } from "../utils/useMousePosition";
import type { SpreadText } from "../types/room/types";

(defaultDeck as Deck).cards.forEach((card) => {
    card.filePath = defaultDeck.basePath + "/" + card.artwork;
  });

const client = createClient({
  publicApiKey: "pk_dev_an71FM7A2wmven8pNnCHmBXp",
});

type Presence = {
  cursor: Vec2d | null;
  prevCursor: Vec2d | null;
  activeShape: Shape | null;
  color: string;
};
type Storage = {
  state: RoomState;
  deck: Deck;
  cards: LiveList<SpreadCard>;
  shapes: LiveList<Shape>;
  texts: LiveList<SpreadText>;
  spread: Spread;
  handHoverIndex: number;
  avOn: boolean;
  reversesOn: boolean;
  theme: RoomTheme;
  searchTerm: string;
  openSideMenu: null | SideMenu;
  background: {name: string, filename: string};
  isCardSearchOpen: boolean;
  activeSearcher: string;
  usernames: string[]
};

export const {
  suspense: {
    RoomProvider,
    useOthers,
    useUpdateMyPresence,
    useStorage,
    useMutation,
    useSelf,
  },
} = createRoomContext<Presence, Storage>(client);

export const initialStorage: Storage = {
  state: RoomState.Ready,
  deck: defaultDeck as unknown as Deck,
  cards: new LiveList<SpreadCard>(),
  shapes: new LiveList<Shape>(),
  texts: new LiveList<SpreadText>(),
  spread: defaultSpread as unknown as Spread,
  theme: "light",
  handHoverIndex: -99,
  avOn: true,
  reversesOn: true,
  openSideMenu: null,
  searchTerm: "",
  background:  {name: "Light", filename: ""},
  isCardSearchOpen: false,
  activeSearcher: "",
  usernames: []
};

export function useMutateRoomState() {
  return useMutation(
    ({ storage }, state: RoomState) => storage.set("state", state),
    []
  );
}

export function useMutateStorage() {
  return useMutation(
    ({ storage }, setter: (storage: LiveObject<Storage>) => void) =>
      setter(storage),
    []
  );
}
