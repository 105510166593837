//@ts-ignore
import { DateTimeFormatOptions } from "intl";
import { format } from "path";
import * as dateFns from "date-fns";

import { Service } from "~/api/calendarApi";
import * as luxon from "luxon";

export const stripeTimestampToReadable = (timestamp: string | undefined) => {
  if (!timestamp) return;
  const date = new Date(parseInt(timestamp) * 1000);
  const options: DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, // Use browser's timezone
  };
  const shortString = date.toLocaleString("en-US", options);

  const readableString = `<span style="font-weight:500;">${shortString}</span>`;
  return readableString;
};

export interface Timeslot {
  start: string; // e.g. "1100"
  end: string; // e.g. "1300"
}

export interface DayAvailability {
  times: Timeslot[];
  available: boolean;
}

export interface WeeklyAvailability {
  monday: DayAvailability;
  tuesday: DayAvailability;
  wednesday: DayAvailability;
  thursday: DayAvailability;
  friday: DayAvailability;
  saturday: DayAvailability;
  sunday: DayAvailability;
}

export interface AvailabilityRequest {
  timezoneIana: string; // e.g. pacific/Los_Angeles
  services: Service[];
  availability: WeeklyAvailability;
}

const emptyDayA: DayAvailability = {
  times: [],
  available: false,
};

export const emptyWeeklyAvailability: WeeklyAvailability = {
  monday: { ...emptyDayA },
  tuesday: { ...emptyDayA },
  wednesday: { ...emptyDayA },
  thursday: { ...emptyDayA },
  friday: { ...emptyDayA },
  saturday: { ...emptyDayA },
  sunday: { ...emptyDayA },
};

export const emptyAvailabilityRequest: AvailabilityRequest = {
  timezoneIana: "",
  services: [],
  availability: { ...emptyWeeklyAvailability },
};

// return in yyyMMdd format
export const allDatesForNextNDays = (n: number, timezone: string) => {
  // create list of all dates between now and n days from now
  const allDates = [];

  for (let i = 0; i < n; i++) {
    const date = dateFns.addDays(new Date(), i);
    allDates.push(date);
  }

  const allDatesWithTags = allDates.map((date) => {
    const timezoneDate = luxon.DateTime.fromJSDate(date, {
      zone: timezone,
    }).toJSDate();
    return {
      date: timezoneDate,
      formattedDate: dateFns.format(new Date(timezoneDate), "yyyyMMdd"),
    };
  });

  return allDatesWithTags;
};

// Example: January 1st I'm available from 8am to 10am and 11am to 12pm
// You've asked me for the pro's availability for January 1st from 9am to 11am in Asia/Tokyo
// {
//   timeSlots: {
//     "20230101": [
//       {
//         startTime: "2023-10-03T08:00:00.000+9:00",
//         readableTime: "8:00 AM",
//         serviceDurations: [30, 60],
//         readableEndTimes: { "30": "8:30 AM", "60": "9:00 AM" }
//       },
//     ]
//     ... other days
//   },
//   unavailableDates: ["20230102", ... other unavailable days],
//   timeZone: "Asia/Tokyo"
//   today: "20230101",
//   endDate: "20230130",
// }

export interface ServiceAvailability {
  // e.g. "20230101" for Jan 1, 2023
  timeSlots: {
    [yyyymmddDate: string]: {
      startTime: string; // ISO 8601 format, e.g. "2023-10-03T08:00:00.000Z"
      readableTime: string; // e.g. "8:00 AM"
      serviceDurations: number[]; // Array of service durations in minutes, e.g. [30, 60]
      readableEndTimes: {
        [duration: string]: string;
      }; // Array of end times, e.g. ["8:30 AM", "9:00 AM"]
    }[];
  };
  unavailableDates: string[]; // e.g. "20230101" for Jan 1, 2023
  timeZone: string; // IANA Timezone
  today: string; // e.g. "20230101" for Jan 1, 2023
  endDate: string; // e.g. "20230101" for Jan 1, 2023
}

export const DaysOfTheWeek = [
  { name: "Monday", shortName: "Mon" },
  { name: "Tuesday", shortName: "Tue" },
  { name: "Wednesday", shortName: "Wed" },
  { name: "Thursday", shortName: "Thu" },
  { name: "Friday", shortName: "Fri" },
  { name: "Saturday", shortName: "Sat" },
  { name: "Sunday", shortName: "Sun" },
];

export const readableTimeToApiTime = (readableTime: string) => {
  const [time, ampm] = readableTime.split(" ");
  const [hour, minute] = time.split(":");
  let newHour = ampm === "pm" ? parseInt(hour) + 12 : parseInt(hour);
  if (newHour === 12 || newHour === 24) {
    newHour -= 12;
  }
  const stringHour = newHour < 10 ? `0${newHour}` : newHour;
  if (ampm === "pm") {
    return `${stringHour}${minute}`;
  } else {
    return `${stringHour}${minute}`;
  }
};

export const apiTimeToReadableTime = (apiTime: string) => {
  const hour = apiTime.slice(0, 2);
  const minute = apiTime.slice(2, 4);
  const hourInt = parseInt(hour);
  const ampm = hourInt < 12 ? "am" : "pm";
  let hour12 = hourInt > 12 ? hourInt - 12 : hourInt;
  if (hour12 === 0) hour12 = 12;
  return `${hour12}:${minute} ${ampm}`;
};

export const generateReadableTimesToApiTimes = () => {
  const readableTimesToApiTime: { [key: string]: string } = {};
  for (let i = 0; i < 24; i++) {
    let ampmHour = i < 12 ? i : i - 12;
    if (ampmHour === 0) ampmHour = 12;
    let ampm = "pm";
    if (i < 12) {
      ampm = "am";
    }
    const readableTime = `${ampmHour}:00 ${ampm}`;
    const readableTimeHalf = `${ampmHour}:30 ${ampm}`;
    readableTimesToApiTime[readableTime] = readableTimeToApiTime(readableTime);
    readableTimesToApiTime[readableTimeHalf] =
      readableTimeToApiTime(readableTimeHalf);
  }
  return readableTimesToApiTime;
};

export const ReadableTimesToApiTimes = generateReadableTimesToApiTimes();

//@ts-ignore
export const IANATimezones = Intl.supportedValuesOf("timeZone") as string[];

export const yyyyMMddToDate = (date: string) => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  return new Date(`${year}-${month}-${day}T00:00:00`);
};

export const dateNumFromyyyyMMdd = (date: string) => {
  console.log(date);
  const dateNum = date.slice(6, 8);
  if (dateNum[0] === "0") {
    return dateNum[1];
  }
  return dateNum;
};
