import axios from 'axios'
import axiosRetry from 'axios-retry'
import { LOCAL_STORAGE_USER } from '../utils/consts.js'
import { sendServerErrorToastWithFallbackCode, sendServerFriendlyToastWithFallbackCode } from '~/utils/handleToasts'
import * as Sentry from '@sentry/browser'

const API_URL = import.meta.env.VITE_API_URL

const getUserToken = () => {
  const localStorageEntry = localStorage.getItem(LOCAL_STORAGE_USER)
  if (!localStorageEntry) {
    return '';
  }
  
  const savedUser = JSON.parse(localStorageEntry)
  return savedUser ? savedUser.token : ''
}

// configure axios instance
const instance = axios.create({
  baseURL: `${API_URL}`,
})

axiosRetry(instance, {
  retries: 3,
  onRetry: (retryCount, error, requestConfig) => {
    Sentry.captureMessage(
      `Retrying request ${requestConfig.url} for the ${retryCount} time. Error: ${error.message}`,
      'warning'
    )
    Sentry
  },
  retryDelay: axiosRetry.exponentialDelay
});

instance.defaults.headers.post['Content-Type'] = 'application/json'
instance.defaults.headers.common['Authorization'] = 'Bearer ' + getUserToken()

instance.interceptors.request.use(
  function (config) {
    const token = getUserToken()

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export const setAuthToken = (token: string) => {
  if (token) {
    //applying token
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    //deleting the token from header
    delete instance.defaults.headers.common['Authorization']
  }
}


// Handle Toast Messages

// Forward all responses to the toast handler
instance.interceptors.response.use(
  function (response) {
    sendServerFriendlyToastWithFallbackCode(response, 1132)
    return response
  },
  function (error) {
    sendServerErrorToastWithFallbackCode(error, 1150)
    return Promise.reject(error)
  }
)

export default instance
