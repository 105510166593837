import { styled } from "~/ui/style/stitches.config";
import { useStorage } from "~/state/liveblocks.config";
import { useEffect, useState, useContext } from "react";
import { ToolBarItem } from "./Toolbar";
import { useSnapshot } from "valtio";
import { useLocation } from "wouter";
import { AnimatePresence, motion } from "framer-motion";
import { DeckMenu } from "./DeckMenu";
import { DeckInfoBox } from "./DeckInfoBox";
import { localState } from "~/state/state";
import { AuthContext } from "~/utils/useAuth";
import { HelpButton } from "../components/HelpButton";

export type ConfirmMenuLabel =
  | null
  | "New deck"
  | "Deck Info"
  | "Copy link"
  | "Leave room"
  | "New spread"
  | "Camera"
  | "Microphone";

export const BottomBar = () => {
  const [, setLocation] = useLocation();

  const { user } = useContext(AuthContext)
  const { isOnMobile } = useSnapshot(localState);
  const [userDecks, setUserDecks] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      if (!user || !user.username) return;
      setUserDecks([...user.decks]);
    })();
  }, [user?.username]);

  const theme = useStorage((root) => root.theme);
  const [confirmMenuOpen, setConfirmMenuOpen] =
    useState<ConfirmMenuLabel>(null);
  const [isLinkCopyOpen, setIsLinkCopyOpen] = useState(false);

  const deckMenuBarItem = {
    label: "Change deck",
    icon: "room/plus-deck.svg",
    isVisible: () => true,

    isSelected: () => confirmMenuOpen === "New deck",
    onClick: () =>
      confirmMenuOpen === "New deck"
        ? setConfirmMenuOpen(null)
        : setConfirmMenuOpen("New deck"),
    secondMenu: (
      <div className="confirm-menu spread-menu bottom-bar-confirm-menu">
        <DeckMenu
          isOpen={true}
          setIsOpen={setConfirmMenuOpen}
          userDecks={userDecks}
        ></DeckMenu>
      </div>
    ),
  };

  const rightButtonContent = [
    {
      label: "Copy link",
      icon: "room/copy-link-icon.svg",
      isVisible: () => true,
      onClick: () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
          setConfirmMenuOpen("Copy link");
          setIsLinkCopyOpen(true);
          window.setTimeout(() => setIsLinkCopyOpen(false), 3000);
        });
      },
      isSelected: () => confirmMenuOpen === "Copy link" && isLinkCopyOpen,
      secondMenu: (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="confirm-menu copy-link-confirm"
            style={{ marginRight: "36px" }}
          >
            Room link copied
          </motion.div>
        </AnimatePresence>
      ),
    },
  ];

  return (
    <StyledBottomBar Theme={theme} onClick={(e) => e.stopPropagation()}>
      <div className="left-buttons">
        <ToolBarItem
          bottomBar={true}
          changeDeckMenu={true}
          confirmMenuOpen={confirmMenuOpen}
          barItem={deckMenuBarItem}
        ></ToolBarItem>
        <DeckInfoBox
          confirmMenuOpen={confirmMenuOpen}
          onClick={() =>
            confirmMenuOpen === "Deck Info"
              ? setConfirmMenuOpen(null)
              : setConfirmMenuOpen("Deck Info")
          }
        />
      </div>
      <div className="right-buttons">
        {rightButtonContent.map((buttonContent, i) => (
          <ToolBarItem
            key={i}
            bottomBar={true}
            confirmMenuOpen={confirmMenuOpen}
            barItem={buttonContent}
          ></ToolBarItem>
        ))}
      </div>
      {!isOnMobile && <HelpButton />}
    </StyledBottomBar>
  );
};

const StyledBottomBar = styled("div", {
  width: "100%",
  height: "46px",
  backgroundColor: "$wash",
  borderTop: "1px solid black",
  position: "absolute",
  bottom: 0,
  left: 0,
  display: "flex",
  zIndex: 10,
  justifyContent: "space-between",
  alignItems: "center",

  "& .right-buttons": {
    display: "flex",
    marginRight: "8px",
  },
  "& .copy-link-confirm": {
    bottom: 0,
    zIndex: "4",
    top: "-55px !important",
    right: "-30px !important",
    padding: 0,
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    borderRadius: "10px",
    border: "none !important",
    "@mobile": {
      right: "0px !important",
      marginRight: "0 !important",
    },
  },
  variants: {
    Theme: {
      dark: {
        backgroundColor: "$darkwash",
        borderTop: "1px solid white",
      },
      light: {},
    },
  },
});
