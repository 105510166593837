import { styled } from "~/ui/style/stitches.config";
import { ToolBarItem } from "./Toolbar";
import { CheckIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { useStorage } from "~/state/liveblocks.config";
import { useSnapshot } from "valtio";
import { RoomTheme } from "~/types/room/types";
import { localState } from "~/state/state";

import React, { useEffect, useState } from "react";
import { StyledLeftListItem, StyledLeftMenu } from "./StyledMenus";
import { Button } from "../components/Button";
import { ConfirmMenuLabel } from "./BottomBar";
import { useDaily, useLocalParticipant } from "@daily-co/daily-react-hooks";
import { DailyCall } from "@daily-co/daily-js";

export function AVControls({
  useDevices,

  setCameraOffAndMicMuted,
}: {
  useDevices: Function;
  setCameraOffAndMicMuted: Function;
}) {
  const theme = useStorage((root) => root.theme);
  const { isOnMobile } = useSnapshot(localState);

  const {
    hasCamError,
    cameras,
    camState,
    setCamera,
    hasMicError,
    microphones,
    micState,
    setMicrophone,
    speakers,
    setSpeaker,
    refreshDevices,
  } = useDevices();

  const localParticipant = useLocalParticipant();
  const isMuted = !localParticipant?.audio;
  const isCameraOff = !localParticipant?.video;

  useEffect(() => {
    let deviceRefresh;
    if (cameras.length === 0 || microphones.length === 0) {
      deviceRefresh = window.setTimeout(() => refreshDevices(), 1000);
    } else {
      window.clearTimeout(deviceRefresh);
    }
  }, [cameras, microphones]);

  const [confirmMenuOpen, setConfirmMenuOpen] =
    useState<ConfirmMenuLabel>(null);

  const toggleConfirmMenu = (menuLabel: ConfirmMenuLabel) => {
    confirmMenuOpen === menuLabel
      ? setConfirmMenuOpen(null)
      : setConfirmMenuOpen(menuLabel);
  };

  return (
    <StyledAVControls
      style={{ WebkitUserSelect: "none", zIndex: 5 }}
      Theme={theme}
      onClick={(e) => e.stopPropagation()}
    >
      <div id="mic-options">
        <ToolBarItem
          AV={true}
          barItem={{
            label: null,
            icon: `../images/room/${isMuted ? "micoff" : "micon"}.svg`,
            isVisible: () => true,
            onClick: () => {
              if (microphones?.length === 0) {
                setConfirmMenuOpen("Microphone");
              } else {
                setCameraOffAndMicMuted(isCameraOff, !isMuted);
              }
            },
            confirmMenuLabel: "Microphone",
            isSelected: () => isMuted || confirmMenuOpen === "Microphone",
            secondMenu:
              microphones?.length > 0 ? (
                <InputMenu
                  theme={theme}
                  offset={"-4px"}
                  inputs={microphones}
                  setInput={setMicrophone}
                  openCloseFn={() => {
                    toggleConfirmMenu("Microphone");
                  }}
                />
              ) : (
                <ErrorMenu
                  offset={"-4px"}
                  errorMsg={
                    "Your microphone is blocked. Allow access through your browser’s permission settings."
                  }
                  openCloseFn={() => toggleConfirmMenu("Microphone")}
                  theme={theme}
                />
              ),
          }}
          confirmMenuOpen={confirmMenuOpen}
        />
        <ToolBarItem
          AV
          narrow
          barItem={{
            label: null,
            icon: (
              <div className="down-icon">
                <ChevronDownIcon
                  style={{
                    transform: `rotate(${
                      confirmMenuOpen === "Microphone" ? "180deg" : "0"
                    })`,
                  }}
                />
              </div>
            ),
            isVisible: () => true,
            onClick: () => {
              toggleConfirmMenu("Microphone");
            },
            isSelected: () => false,
          }}
          confirmMenuOpen={null}
        />
      </div>
      <div id="cam-options">
        <ToolBarItem
          AV
          barItem={{
            label: null,
            icon: `../images/room/${isCameraOff ? "camoff" : "camon"}.svg`,
            isVisible: () => true,
            onClick: () => {
              if (cameras?.length === 0) {
                setConfirmMenuOpen("Camera");
              } else {
                setCameraOffAndMicMuted(!isCameraOff, isMuted);
              }
            },
            isSelected: () => isCameraOff || confirmMenuOpen === "Camera",
            confirmMenuLabel: "Camera",
            secondMenu:
              cameras?.length > 0 ? (
                <InputMenu
                  theme={theme}
                  offset={"1px"}
                  inputs={cameras}
                  setInput={setCamera}
                  openCloseFn={() => {
                    toggleConfirmMenu("Camera");
                  }}
                />
              ) : (
                <ErrorMenu
                  offset={"1px"}
                  errorMsg={
                    "Your camera is blocked. Allow access through your browser’s permission settings."
                  }
                  openCloseFn={() => toggleConfirmMenu("Camera")}
                  theme={theme}
                />
              ),
          }}
          confirmMenuOpen={confirmMenuOpen}
        />
        <ToolBarItem
          AV={true}
          narrow={true}
          barItem={{
            label: null,
            icon: (
              <div className="down-icon">
                <ChevronDownIcon
                  className="down-icon"
                  style={{
                    transform: `rotate(${
                      confirmMenuOpen === "Camera" ? "180deg" : "0"
                    })`,
                  }}
                />
              </div>
            ),
            isVisible: () => true,
            onClick: () => {
              toggleConfirmMenu("Camera");
            },
            isSelected: () => false,
          }}
          confirmMenuOpen={null}
        />
      </div>
    </StyledAVControls>
  );
}

const ErrorMenu = ({
  errorMsg,
  openCloseFn,
  theme,
  offset,
}: {
  errorMsg: string;
  openCloseFn: Function;
  theme: RoomTheme;
  offset: string;
}) => {
  const { isOnMobile } = useSnapshot(localState);

  return (
    <StyledLeftMenu
      Theme={theme === "dark" ? "light" : "dark"}
      style={{
        position: "absolute",
        paddingTop: "32px",
        top: isOnMobile ? "unset" : "36px",
        bottom: isOnMobile ? "36px" : "unset",
        left: offset,
        border: "none",
      }}
    >
      <Button
        Theme={theme === "dark" ? "light" : "dark"}
        role="menuitem"
        style={{
          padding: "2px 6px",
          position: "absolute",
          right: "3px",
          top: "3px",
          transform: "rotate(90deg)",
        }}
        transparent
        onClick={openCloseFn as React.MouseEventHandler}
      >
        <img style={{ width: "16px" }} src="/images/room/x-icon.png"></img>
      </Button>
      <div
        style={{
          marginTop: "-6px",
          fontSize: "9px",
          paddingLeft: "16px",
          paddingRight: "8px",
          paddingBottom: "8px",
          color: theme === "dark" ? "black" : "white",
        }}
      >
        {errorMsg}
      </div>
    </StyledLeftMenu>
  );
};

const InputMenu = ({
  inputs,
  setInput,
  offset,
  openCloseFn,
  theme,
}: {
  inputs: any;
  setInput: Function;
  offset: string;
  openCloseFn: Function;
  theme: RoomTheme;
}) => {
  const { isOnMobile } = useSnapshot(localState);

  return (
    <StyledLeftMenu
      Theme={theme}
      style={{
        position: "absolute",
        paddingTop: "32px",
        top: isOnMobile ? "unset" : "36px",
        bottom: isOnMobile ? "36px" : "unset",
        left: offset,
      }}
    >
      <Button
        Theme={theme}
        className="arrow-button"
        role="menuitem"
        style={{
          padding: "2px 6px",
          position: "absolute",
          left: "10px",
          top: "8px",

          transform: "rotate(90deg)",
        }}
        transparent
        onClick={openCloseFn as React.MouseEventHandler}
      >
        &larr;
      </Button>
      {inputs.map((input: any, i: number) => (
        <StyledLeftListItem Theme={theme} key={i}>
          <Button
            Theme={theme}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "8px 8px",
              width: "100%",
              fontSize: "12px",
            }}
            transparent
            menuOption
            onClick={(e) => {
              setInput(input.device.deviceId);
              e.preventDefault();
            }}
          >
            {input.device.label}
            <CheckIcon
              style={{
                flexShrink: 0,
                opacity: input.selected ? 1 : 0,
              }}
            />
          </Button>
        </StyledLeftListItem>
      ))}
    </StyledLeftMenu>
  );
};

export function MuteIndicator() {
  const theme = useStorage((root) => root.theme);

  return (
    <StyledMuteIndicator Theme={theme}>
      <img src="../images/room/micoff.svg" />
    </StyledMuteIndicator>
  );
}

const StyledMuteIndicator = styled("div", {
  position: "absolute",
  bottom: "2px",
  right: "2px",
  height: "24px",
  width: "24px",
  zIndex: "1",
  backgroundColor: "$washA800",
  borderRadius: "50%",
  padding: "2px",
  variants: {
    Theme: {
      dark: {
        backgroundColor: "$darkwashA800",
        "& img": {
          filter: "invert(1)",
        },
      },
      light: {},
    },
  },
});

const StyledAVControls = styled("div", {
  zIndex: 2,

  position: "absolute",
  //   zIndex: "1",
  top: "calc($navHeight + $4)",
  left: "$3",
  display: "flex",
  backgroundColor: "$wash",
  border: "1px solid $darkwashA800",
  borderRadius: "12px",
  padding: "1px 6px 1px 4px",
  "& img": {
    pointerEvents: "none",
  },
  "& #mic-options, & #cam-options": {
    display: "flex",
  },
  "& #mic-options": {
    marginRight: "6px",
  },
  "@mobile": {
    top: "unset !important",
    bottom: "calc($navHeight + $4)",
  },
  variants: {
    Theme: {
      dark: {
        backgroundColor: "$darkwash",
        borderColor: "$washA800",
      },
      light: {},
    },
  },
});
