import { keyframes, styled } from "~/ui/style/stitches.config";
import { useEffect, useState, useContext } from "react";
import { getUser } from "~/api/userApi";
import { AuthContext } from "~/utils/useAuth";
import { Button } from "../components/Button";
import { useLocation } from "wouter";
import { useSnapshot } from "valtio";

import { StyledProfilePic } from "../components/ProfilePic";
import { localState } from "~/state/state";

export const FavesBar = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Function;
}) => {
  const [faveIds, setFaveIds] = useState<string[]>([]);
  const [location, setLocation] = useLocation();
  const [scrollOffset, setScrollOffset] = useState(0);
  const [activeUsername, setActiveUsername] = useState("");
  const { isOnMobile } = useSnapshot(localState);

  const [selfFavorite, setSelfFavorite] = useState({
    name: "string",
    userName: "",
    profilePic: "",
    bio: "",
  });

  const [favorites, setFavorites] = useState<
    { name: string; userName: string; profilePic: string; bio: string }[]
  >([]);
  const { user } = useContext(AuthContext);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    (async () => {
      if (user?.uuid) {
        setSelfFavorite({
          name: user.name,
          userName: user.username,
          profilePic: user.profilePic,
          bio: user.bio,
        });
        setFaveIds(user.favorites);
      }
    })();
  }, [user, user?.uuid]);

  useEffect(() => {
    let newFavorites: {
      name: string;
      userName: string;
      profilePic: string;
      bio: string;
    }[] = [];
    faveIds?.forEach(async (uuid) => {
      const usr = await getUser(uuid);
      const newFavorite = {
        userName: usr.username,
        name: usr.name,
        bio: usr.bio,
        profilePic: usr.profilePic,
      };
      newFavorites.push(newFavorite);
      if (newFavorites.length > favorites.length)
        setFavorites([...newFavorites]);
    });
  }, [faveIds]);

  return (
    <StyledFavesMenu
      isOpen={isOpen}
      onPointerEnter={(e) => {
        if (!isOpen && !isOnMobile) {
          e.stopPropagation();
          setIsOpen(true);
        }
      }}
      onPointerLeave={(e) => {
        if (isOpen && !isOnMobile) {
          e.stopPropagation();
          setIsOpen(false);
        }
      }}
      onClick={(e) => {
        if (isOnMobile) {
          e.stopPropagation();
          if (isOpen) setIsOpen(false);
          else setIsOpen(true);
        }
      }}
    >
      <div className="primary-container-container-container">
        <div className="primary-container-container">
          <div
            id="primary-friends-container"
            className="primary-container"
            onScroll={(e) => {
              if ((e.target as HTMLDivElement).scrollTop)
                setScrollOffset(2 * (e.target as HTMLDivElement).scrollTop);
            }}
          >
            <div className="heart-container">
              <div className="profile-title">Saved profiles</div>
              <img className="heart" src="/images/room/heart.svg"></img>
            </div>
            {[
              selfFavorite,
              ...favorites.filter(
                (fave) => fave.userName !== selfFavorite.userName
              ),
            ].map(({ name, userName, profilePic, bio }) => {
              return (
                <div
                  onPointerEnter={() => {
                    if (!isOnMobile) setActiveUsername(userName);
                  }}
                  onClick={() => {
                    if (isOnMobile) setActiveUsername(userName);
                  }}
                  className={`favorite-container `}
                  key={userName}
                >
                  <div
                    className={`favorite active-${activeUsername === userName}`}
                  >
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={(e) => {
                        if (isOpen && activeUsername === userName) {
                          setLocation("/profile/" + userName);
                        } else {
                          if (!isOpen) setIsOpen(true);
                          else setActiveUsername(userName);
                          e.stopPropagation();
                        }
                      }}
                    >
                      <StyledProfilePic
                        className="profile-pic"
                        css={{ $$size: "30px", $$padding: 0 }}
                        src={`/images/profile-pics/${profilePic}`}
                      />
                      <div className="name">{name}</div>
                    </div>
                    <div
                      className="info-container"
                      style={{ marginTop: `-${scrollOffset}px` }}
                    >
                      <div className="info-name">{name}</div>
                      <div className="bio">{bio}</div>
                      <Button
                        style={{
                          marginTop: "6px",
                          marginBottom: "0px",
                          width: "80%",
                        }}
                        lightweight
                        onClick={() => {
                          setLocation("/profile/" + userName);
                        }}
                      >
                        Visit profile
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </StyledFavesMenu>
  );
};

const fadeIn50 = keyframes({
  "0%": { opacity: 0 },
  "50%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const StyledFavesMenu = styled("div", {
  position: "fixed",
  right: "16px",
  zIndex: "999",
  height: "100%",

  "& .primary-container-container-container": {
    display: "flex",
    alignItems: "center",
    height: "100%",
    position: "relative",
  },

  "& .primary-container": {
    cursor: "pointer",
    width: "44px",
    transition: "width 0.3s",
    maxHeight: "calc(100vh - 180px - 140px)",
    position: "relative",

    "@mobile": {
      maxHeight: "calc(100vh - 280px)",
    },
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "18px",
    padding: "12px 6px",
    display: "flex",
    paddingTop: "36px",
    overflowY: "scroll",
    overflowX: "hidden",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      width: "0px",
    },

    "& .heart-container": {
      display: "flex",
      marginBottom: "12px",
      backgroundColor: "white",
      position: "absolute",
      zIndex: "9",
      height: "36px",
      width: "44px",
      left: 0,
      top: 0,
      paddingTop: "9px",
      borderRadius: "16px 16px 0px 0",
      borderLeft: "1px solid transparent",
      borderTop: "1px solid transparent",
      borderRight: "1px solid transparent",
      transition: "width 0.3s",
    },
    "& .heart": {
      position: "absolute",
      marginLeft: "10.5px",
      transition: "transform 0.3s",
      width: "20px",
      height: "20px",
    },
    "& .profile-title": {
      fontFamily: "KeplerLightCondensed",
      position: "absolute",
      textTransform: "uppercase",
      fontSize: "18px",
      "@mobile": {
        fontSize: "20px",
      },
      width: "100px",
      marginLeft: "10px",
      opacity: 0,
      marginTop: "2px",
    },
    "& .profile-pic": {
      marginTop: "4px",
      marginBottom: "4px",
    },
    "& .favorite-container": {
      width: "100%",
      position: "relative",
    },

    "& .favorite": {
      display: "flex",
      alignItems: "center",
      position: "relative",

      "& .name": {
        marginLeft: "6px",
        fontSize: "12px",
        "@mobile": {
          fontSize: "14px",
        },
        paddingRight: "8px",
        opacity: 0,
        overflowWrap: "break-word",
        width: "114px",
      },
      "& .info-container": {
        "&:before": {
          position: "absolute",
          content: "",
          width: "40px",
          right: "-20px",
          top: "10px",
          bottom: 0,
          margin: "auto",
          height: "40px",
          pointerEvents: "initial",
          "@mobile": {
            pointerEvents: "none",
          },
        },
        position: "fixed",
        fontSize: "12px",
        "@mobile": {
          fontSize: "14px",
        },
        right: "183px",
        width: "150px",
        borderRadius: "16px",
        backgroundColor: "white",
        border: "1px solid black",
        padding: "14px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        opacity: 0,
        pointerEvents: "none",
        "& .info-name": {
          fontWeight: "600",
          whiteSpace: "wrap",
          overflowWrap: "break-word",
          marginBottom: "8px",
          width: "100%",
        },
        "& .bio": {
          width: "100%",
          maxHeight: "114px",
          display: "-webkit-box",
          WebkitLineClamp: 4,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverlfow: "ellipsis",
          marginBottom: "4px",
        },
      },
    },
  },

  variants: {
    isOpen: {
      true: {
        "& .primary-container": {
          width: "160px",
          "& .heart-container": {
            width: "160px",
          },
          "& .profile-title": {
            opacity: 1,
            transition: "opacity 0.3s",
            transitionDelay: "0.2s",
          },
          "& .heart": {
            transform: "translateX(116px)",
          },

          "& .favorite": {
            "&:hover": {
              backgroundColor: "$gray100",
              borderRadius: "16px",
              "& .info-container": {
                opacity: 1,
                pointerEvents: "auto",
              },
            },
            "& .name": {
              opacity: 1,
              transition: "opacity 0.3s",
              transitionDelay: "0.2s",
            },
          },
        },
      },
    },
  },
});
