import { motion } from "framer-motion";
import { keyframes, styled } from "../style/stitches.config";

export const Marquee = ({
  text,
  page,
  style,
}: {
  text: string;
  page: "profile" | "home" | "pro";
  style?: Object;
}) => {
  return (
    <StyledMarquee
      style={style}
      page={page}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      key="marquee"
      className="marquee-bar"
    >
      <div
        style={{ display: "flex" }}
        dangerouslySetInnerHTML={{
          __html: `${text.repeat(100)}`,
        }}
      ></div>
    </StyledMarquee>
  );
};

const marquee = keyframes({
  "0%": {
    transform: "translateX(-175px) ",
  },

  "100%": {
    transform: "translateX(0) ",
  },
});

const StyledMarquee = styled(motion.div, {
  variants: {
    page: {
      pro: {
        width: "calc(100% - 24px)",
        overflow: "hidden",
        position: "absolute",
        top: "8px",
        left: "12px",

        fontSize: "10px",
        "& .marquee-text": {
          alignSelf: "center",
          display: "block",
          whiteSpace: "nowrap",
          textDecoration: "none !important",
          marginRight: "3px",
          fontWeight: "300",
          paddingBottom: "4px",
          textTransform: "uppercase",
          animation: `${marquee} 16s infinite linear`,
          width: "380px !important",
          "@mobile": {
            display: "none",
            // marginBottom: "4px",
          },
        },
      },
      home: {
        position: "fixed",
        bottom: "3px",
        fontSize: "12px",
        overflow: "hidden",
        left: 0,
        width: "calc(100vw - 194px)",
        display: "flex",
        paddingBottom: "7px",
        paddingTop: "12px",
        zIndex: "99999",
        "& > *": {},

        "& .marquee-text": {
          alignSelf: "center",
          display: "block",
          whiteSpace: "nowrap",
          textDecoration: "none !important",

          fontWeight: "300",
          paddingBottom: "4px",
          textTransform: "uppercase",
          animation: `${marquee} 16s infinite linear`,
          width: "380px !important",
          "@mobile": {
            display: "none",
            // marginBottom: "4px",
          },
        },

        "@mobile": {
          bottom: "80px",
        },
      },
      profile: {
        bottom: 0,
        overflow: "hidden",
        width: "100vw",
        display: "flex",
        paddingBottom: "8px",
        paddingTop: "12px",

        marginTop: "40px",
        zIndex: "9999",
        "& .marquee-text": {
          alignSelf: "center",
          display: "block",
          whiteSpace: "nowrap",
          flexShrink: 0,
          fontSize: "11px",
          "@smaller": {
            fontSize: "12px",
          },
          fontWeight: "300",
          paddingBottom: "4px",
          textTransform: "uppercase",
          animation: `${marquee} 10s infinite linear`,
          width: "175px !important",
        },
      },
    },
  },
});
