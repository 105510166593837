import { createContext, useState } from "react";
import { useLocation } from "wouter";
import axios, { setAuthToken } from "../api/axiosConfig.js";
import {
  sendErrorToast,
  sendServerErrorToastWithFallbackCode,
  sendServerFriendlyToastWithFallbackCode,
} from "~/utils/handleToasts";
import { AxiosError } from "axios";
import { LOCAL_STORAGE_USER } from "./consts.js";
import { CredentialResponse } from "google-one-tap";
import { sendAnalyticsEvent } from "./analytics.js";
import * as userApi from "../api/userApi.js";
import { UserProfile } from "../api/userApi.js";
import * as Sentry from "@sentry/react";

declare global {
  const google: typeof import("google-one-tap");
}

// --- API Interfaces ---------------

const SIGN_IN_ENDPOINT = "auth/signin";
const SIGN_UP_ENDPOINT = "auth/signup";
const GOOGLE_SIGN_IN_ENDPOINT = "auth/google";
const FORGOT_PASSWORD_ENDPOINT = "auth/forgotPassword";
const RESET_PASSWORD_ENDPOINT = "auth/resetPassword";
const CHECK_PASSWORD_ENDPOINT = "auth/checkPasswordLink";

interface SignUpRequest {
  email: string;
  name: string;
  password: string;
  profilePic: string;
}

interface ForgotPasswordRequest {
  email: string;
  captcha: string;
  origin: string;
}

interface ResetPasswordRequest {
  password: string;
  token: string;
}

interface AuthContext {
  user: UserProfile | null;
  token: string | null;
  getUser: () => Promise<void>;
  updateUser: (update: Partial<UserProfile>) => Promise<void>;
  addOrRemoveFavorite: (uuid: string) => Promise<void>;
  clearUser: () => void;
  signup: (
    email: string,
    name: string,
    password: string,
    profilePic: string
  ) => Promise<void>;
  loginEmail: (email: string, password: string) => void;
  loginGoogle: (googleResponse: CredentialResponse) => void;
  logout: () => void;
  forgotPassword: (email: string, captcha: string) => Promise<void>;
  resetPassword: (password: string, token: string) => Promise<void>;
  checkPasswordLink: (token: string) => Promise<void>;
  processDeckPayment: (
    deckIds: string[],
    paymentIntentId: string,
    paymentGroupId: string
  ) => Promise<void>;
}
export const AuthContext = createContext<AuthContext>({
  user: null,
  token: null,
  getUser: async () => {},
  updateUser: async () => {},
  addOrRemoveFavorite: async () => {},
  clearUser: () => {},
  signup: async () => {},
  loginEmail: () => {},
  loginGoogle: () => {},
  logout: () => {},
  forgotPassword: async () => {},
  resetPassword: async () => {},
  checkPasswordLink: async () => {},
  processDeckPayment: async () => {},
});

export function ProvideAuth({ children }: any) {
  const localStorageEntry = localStorage.getItem(LOCAL_STORAGE_USER);

  const savedUser = localStorageEntry ? JSON.parse(localStorageEntry) : null;
  const savedToken = localStorageEntry
    ? JSON.parse(localStorageEntry).token
    : null;
  const [user, setUser] = useState<UserProfile | null>(savedUser);
  const [token, setToken] = useState<string | null>(savedToken);
  let [, setLocation] = useLocation();

  const clearUser = () => {
    localStorage.removeItem(LOCAL_STORAGE_USER);
    setUser(null);
  };

  const handleErrorWithFallbackCode = (
    error: any,
    fallbackErrorCode: number
  ) => {
    sendServerErrorToastWithFallbackCode(error, fallbackErrorCode);
    // Handle AxiosErrors
    if (error && error instanceof AxiosError && error.response) {
      if (error.response.status === 401) {
        // User is not authenticated
        clearUser();
        Sentry.captureMessage("Unauthenticated user error", "log");
        return;
      }
      if (error.response.status === 403) {
        // User is not authorized
        Sentry.captureMessage("Unauthorized action error", "warning");
        return;
      }
      if (error.response.status === 404) {
        // Resource not found
        Sentry.captureMessage("Resource not found error", "warning");
        return;
      }
      if (error.response.status === 500) {
        // Server error
        Sentry.captureException(error);
        return;
      }
    }
    if (error) throw error;
  };

  const getUser = async (): Promise<void> => {
    try {
      const user = await userApi.getCurrentUser();
      // update local storage user with user data
      const localStorageString = localStorage.getItem(LOCAL_STORAGE_USER) || "";
      const localStorageData = JSON.parse(localStorageString);
      if (localStorageData) {
        localStorage.setItem(
          LOCAL_STORAGE_USER,
          JSON.stringify({ ...localStorageData, ...user })
        );
      }
      setUser(user);
    } catch (error) {
      // Unknown error logging in
      handleErrorWithFallbackCode(error, 1001);
    }
  };

  const updateUser = async (update: Partial<UserProfile>) => {
    try {
      const updatedUser = await userApi.updateUser(update);
      // update local storage user with updatedUser data
      const localStorageString = localStorage.getItem(LOCAL_STORAGE_USER) || "";
      const localStorageData = JSON.parse(localStorageString);

      localStorage.setItem(
        LOCAL_STORAGE_USER,
        JSON.stringify({ ...localStorageData, ...updatedUser })
      );
      setUser(updatedUser);
    } catch (error) {
      // User update error
      handleErrorWithFallbackCode(error, 1003);
    }
  };

  const processDeckPayment = async (
    deckIds: string[],
    paymentIntentId: string,
    paymentGroupId: string
  ): Promise<void> => {
    try {
      const updatedUser = await userApi.processDeckPayment(
        deckIds,
        paymentIntentId,
        paymentGroupId
      );
      // update local storage user with updatedUser data
      const localStorageString = localStorage.getItem(LOCAL_STORAGE_USER) || "";
      const localStorageData = JSON.parse(localStorageString);

      localStorage.setItem(
        LOCAL_STORAGE_USER,
        JSON.stringify({ ...localStorageData, ...updatedUser })
      );
      setUser(updatedUser);
    } catch (error) {
      handleErrorWithFallbackCode(error, 1022);
    }
  };

  const addOrRemoveFavorite = async (favoriteUuid: string) => {
    if (!user) {
      return;
    }
    const currentFavorites = user.favorites;
    const favoriteIndex = currentFavorites.indexOf(favoriteUuid);
    let newFavorites = [...currentFavorites];
    if (favoriteIndex >= 0) {
      newFavorites.splice(favoriteIndex, 1);
    } else {
      newFavorites.push(favoriteUuid);
    }
    updateUser({ favorites: newFavorites });
    sendAnalyticsEvent("edited_favorites");
  };

  const signup = async (
    email: string,
    name: string,
    password: string,
    profilePic: string
  ) => {
    try {
      const signUpData: SignUpRequest = {
        email: email,
        name: name,
        password: password,
        profilePic: profilePic,
      };

      await axios.post(SIGN_UP_ENDPOINT, signUpData);
      sendAnalyticsEvent("sign_up");
      return await loginEmail(email, password);
    } catch (error) {
      handleErrorWithFallbackCode(error, 1004);
    }
  };

  const loginEmail = async (email: string, password: string): Promise<void> => {
    try {
      const authData = (await axios.post(SIGN_IN_ENDPOINT, { email, password }))
        .data as UserProfile & { token: string };
      if (!authData.token) {
        // No token returned from login
        sendErrorToast(1028);
      }
      sendAnalyticsEvent("sign_in");

      setToken(authData.token);
      setAuthToken(authData.token);

      localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(authData));

      // remove token, and set user to memory
      let userData: any = authData;
      delete userData.token;
      const user = userData as UserProfile;
      setUser(user);
    } catch (error) {
      // Login email failed
      handleErrorWithFallbackCode(error, 1002);
    }
  };

  const loginGoogle = async (
    googleResponse: CredentialResponse
  ): Promise<void> => {
    try {
      const authData = (
        await axios.post(GOOGLE_SIGN_IN_ENDPOINT, {
          credential: googleResponse.credential,
        })
      ).data as UserProfile & { token: string; isNewUser: boolean };
      if (authData.isNewUser) {
        sendAnalyticsEvent("sign_up");
      }
      sendAnalyticsEvent("google_sign_in");

      setToken(authData.token);
      setAuthToken(authData.token);

      // remove isNewUser, and save to local storage
      let localStorageData: any = authData;
      delete localStorageData.isNewUser;
      localStorage.setItem(
        LOCAL_STORAGE_USER,
        JSON.stringify(localStorageData)
      );

      // remove token, and set user to memory
      let userData: any = localStorageData;
      delete userData.token;

      const user = userData as UserProfile;
      setUser(user);
    } catch (error) {
      handleErrorWithFallbackCode(error, 1012);
    }
  };

  const logout = () => {
    clearUser();
    sendAnalyticsEvent("sign_out");
    setLocation("/");
  };

  const forgotPassword = async (
    email: string,
    captcha: string
  ): Promise<void> => {
    try {
      const forgotPasswordData: ForgotPasswordRequest = {
        email: email,
        captcha: captcha,
        origin: location.origin,
      };
      const res = await axios.post<any, any & { message: string }>(
        FORGOT_PASSWORD_ENDPOINT,
        forgotPasswordData
      );
      sendAnalyticsEvent("forgot_password_issued");
      // Link sent successfully
      sendServerFriendlyToastWithFallbackCode(res, 1006);
    } catch (error) {
      // Error with password reset
      handleErrorWithFallbackCode(error, 1005);
    }
  };

  const resetPassword = async (
    password: string,
    token: string
  ): Promise<void> => {
    try {
      const resetPasswordData: ResetPasswordRequest = {
        password: password,
        token: token,
      };
      await axios.post(RESET_PASSWORD_ENDPOINT, resetPasswordData);
      sendAnalyticsEvent("password_reset");
    } catch (error) {
      handleErrorWithFallbackCode(error, 1007);
    }
  };

  const checkPasswordLink = async (token: string): Promise<void> => {
    try {
      await axios.post(CHECK_PASSWORD_ENDPOINT, {
        token: token,
      });
    } catch (error) {
      handleErrorWithFallbackCode(error, 1008);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        getUser,
        updateUser,
        addOrRemoveFavorite,
        clearUser,
        signup,
        loginEmail,
        loginGoogle,
        logout,
        forgotPassword,
        resetPassword,
        checkPasswordLink,
        processDeckPayment,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
