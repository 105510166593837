import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "wouter";

import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { styled } from "~/ui/style/stitches.config";
import { Button } from "~/ui/components/Button";
import { CredentialResponse } from "google-one-tap";
import {
  DISPLAY_NAME_CHAR_LIMIT,
  PASSWORD_VALIDATION_REGEX,
} from "~/utils/consts";
import { Message, StyledMessage } from "~/ui/components/Message";
import { localState } from "~/state/state";
import { getRandomProfilePic } from "~/utils/profilePic";
import { getUser } from "~/api/userApi";
import { getRoom } from "~/api/roomApi";
import { sendAnalyticsEvent } from "~/utils/analytics";
import { BookingRegistrationSurvey, Survey } from "~/ui/menus/Survey";
import { AuthContext } from "~/utils/useAuth";
import { AuthFormState, StyledClickableText } from "~/pages/login/Auth";

type State = {
  email: string;
  name: string;
  password: string;
  profilePic: string;
  isSubmitting: boolean;
  errorMessage: string | null;
};

const initialState: State = {
  email: "",
  name: "",
  profilePic: "",
  password: "",
  isSubmitting: false,
  errorMessage: null,
};

export const SignupForm = ({
  setAuthState,
  shouldRedirectAfterSignIn,
  isInBookingFlow,
  price,
  hostUserName,
  onSurveySubmit,
}: {
  setAuthState: Function;
  shouldRedirectAfterSignIn: boolean;
  isInBookingFlow?: boolean;
  price?: number;
  hostUserName?: string;
  onSurveySubmit?: Function;
}) => {
  const [data, setData] = useState(initialState);
  const [email, setEmail] = useState("");
  const [showDisplayError, setShowDisplayError] = useState(false);
  const [invitedUser, setInvitedUser] = useState({ name: "", pic: "" });
  const [invitedBy, setInvitedBy] = useState<string | null>(null);
  const { user, loginGoogle, signup } = useContext(AuthContext);
  const [inSurvey, setInSurvey] = useState(false);

  const query = window.location.search;
  const urlQueryParams = new URLSearchParams(query);
  const dest = urlQueryParams.get("dest") || "";
  const host = window.location.host;

  useEffect(() => {
    // get room id and look up host
    const roomName = urlQueryParams.get("dest")?.split("/room/")[1];
    if (!roomName) return;
    (async () => {
      const room = await getRoom(roomName);
      if (room?.owner) setInvitedBy(room.owner);
    })();
  }, []);

  useEffect(() => {
    localState.isConnecting = false;
    localState.isConnected = false;
  }, []);

  useEffect(() => {
    (async () => {
      if (invitedBy) {
        sendAnalyticsEvent("user_invited", {
          invited_by_uuid: invitedBy,
        });
        const res = await getUser(invitedBy)
          .then((user) => {
            setInvitedUser({
              name: user.name,
              pic: `<img src="/images/profile-pics/${user.profilePic}"/>`,
            });
          })
          .catch((err) => {});
      }
    })();
  }, [invitedBy]);

  let [location, setLocation] = useLocation();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const redirectAfterSignIn = () => {
    if (!inSurvey) {
      setInSurvey(true);
      return;
    }
  };

  const handleGoogleSignin = async (response: CredentialResponse) => {
    try {
      await loginGoogle(response);
      redirectAfterSignIn();
    } catch (error) {
      let errorMessage = null;
      if (error instanceof AxiosError) {
        errorMessage = error.message;
      }
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: errorMessage,
      });
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (window.google) {
      google.accounts.id.initialize({
        client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID as string,
        callback: handleGoogleSignin,
      });

      google.accounts.id.renderButton(
        document.getElementById("googlesignin") as HTMLElement,
        {
          type: "standard",
          theme: "outline",
          text: "continue_with",
          shape: "pill",
          logo_alignment: "left",
        }
      );
    }
  }, []);

  const handleSignup = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    event.preventDefault();

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    try {
      const randomProfilePic = getRandomProfilePic();
      await signup(data.email, data.name, data.password, randomProfilePic);
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: null,
      });
      redirectAfterSignIn();
    } catch (error) {
      let errorMessage = null;
      if (error instanceof AxiosError) {
        errorMessage = error.message;
      }
      setData({
        ...data,
        isSubmitting: false,
        errorMessage: errorMessage,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (
        e.data &&
        e.data?.length &&
        e?.data?.includes("Tally.FormSubmitted")
      ) {
        if (onSurveySubmit) onSurveySubmit();
      }
    });
  });

  return (
    <>
      <StyledForm className="auth-form-container">
        {invitedBy && invitedUser.name && (
          <StyledMessage isFading={false} hasPic={true} isError={false}>
            <div
              className="inner-message"
              dangerouslySetInnerHTML={{
                __html: `${invitedUser.pic}<div className="text"><b>${invitedUser.name}</b> invited you to their tarot room</div>`,
              }}
            ></div>
          </StyledMessage>
        )}
        {!invitedBy && <Message />}
        {inSurvey || user ? (
          shouldRedirectAfterSignIn ? (
            <Survey
              dest={`${host}${dest ? decodeURIComponent(dest) : "/"}`}
              email={user?.email}
              username={user?.username}
            ></Survey>
          ) : (
            <>
              <BookingRegistrationSurvey
                username={user?.username}
                email={user?.email}
                price={price}
                host={hostUserName}
              />
            </>
          )
        ) : (
          <>
            <h3 className="title">
              {isInBookingFlow
                ? "Enter Moonlight to continue"
                : "Enter the world of Moonlight"}
            </h3>
            <form onSubmit={handleSignup}>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  required
                  onInvalid={(e) => e.preventDefault()}
                  value={data.email}
                  onChange={handleInputChange}
                ></input>
                <p className="error-message">Invalid email. Check for typos</p>
              </div>
              <div className="input-container">
                <input
                  className={showDisplayError ? "show-display-error" : ""}
                  type="text"
                  name="name"
                  placeholder="Display name"
                  maxLength={DISPLAY_NAME_CHAR_LIMIT}
                  required
                  onInvalid={(e) => e.preventDefault()}
                  value={data.name}
                  onChange={handleInputChange}
                  onClick={() => setShowDisplayError(true)}
                />
                <p className="help-message">You can change this later</p>
                <p className="error-message">Please enter a display name</p>
              </div>
              <div className="input-container">
                <input
                  className="sentry-mask"
                  type="password"
                  name="password"
                  placeholder="Password"
                  required
                  pattern={PASSWORD_VALIDATION_REGEX}
                  onInvalid={(e) => e.preventDefault()}
                  id="inputPasswordRegister"
                  value={data.password}
                  onChange={handleInputChange}
                />
                <p className="help-message">Must be at least 6 characters</p>
                <p className="error-message">Must be at least 6 characters</p>
              </div>

              <Button
                onClick={() => setShowDisplayError(true)}
                type="submit"
                formButton
                disabled={data.isSubmitting}
              >
                Create account
              </Button>
              <h3 className="or">or</h3>
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  height: "30px",
                }}
                id="googlesignin"
              ></div>

              <div className="privacy-policy">
                This is confidential and used only for account updates. By
                signing up you agree to Moonlight’s{" "}
                <a
                  className="link"
                  href="https://moonlight.world/terms"
                  target="_blank"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  className="link"
                  href="https://moonlight.world/privacy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                .
              </div>
            </form>
          </>
        )}
      </StyledForm>
      {!inSurvey && !user && (
        <StyledClickableText
          lowerText
          onClick={() => {
            setAuthState(AuthFormState.LOGIN);
          }}
        >
          Sign in instead
        </StyledClickableText>
      )}
    </>
  );
};

export const StyledForm = styled("div", {
  position: "relative",
  maxWidth: "90%",
  width: "425px",
  flexDirection: "column",
  backgroundColor: "white",
  marginTop: "-20px",
  border: "1px solid black",
  borderRadius: "45px",
  display: "flex",
  alignItems: "center",
  justifyContents: "center",
  textAlign: "center",
  padding: "36px 0",
  boxShadow: "0px 3px 0px 0px #000000",
  "& .title, & .or": {
    fontWeight: "500",
    fontSize: "16px",
  },
  "& .or": {
    marginTop: "9px",
    marginBottom: "-6px",
  },
  "& form": {
    display: "flex",
    flexDirection: "column",
    marginTop: "18px",
    width: "270px",
    "& .error-message, & .help-message": {
      color: "$error",
      fontSize: "9px",
      maxWidth: "270px",
      textAlign: "left",
      marginLeft: "8px",
      marginBottom: "8px",
      position: "absolute",
      bottom: "2px",
      left: "9px",
      pointerEvents: "none",
    },
    "& .error-message": {
      display: "none",
    },
    "& .help-message": {
      color: "#C2C2C2",
    },
    "& .input-container": {
      position: "relative",
    },
    "& input": {
      width: "100%",
      height: "45px",
      border: "1px solid #B6B6B6",
      borderRadius: "14px",
      marginBottom: "6px",
      padding: "16px",
      fontSize: "13px",
      position: "relative",
      "&:focus": {
        outline: "none !important",
        borderColor: "black",
      },

      "&:not(:focus):not(:placeholder-shown):invalid, &:not(:focus):invalid.show-display-error":
        {
          "& ~ .error-message": {
            display: "block",
          },
          "& ~ .help-message": {
            display: "none",
          },
        },
      "&:valid": {
        "& ~ .help-message": {
          display: "none",
        },
      },
    },
  },
  "& .privacy-policy": {
    fontSize: "11px",
    marginTop: "32px",
  },

  variants: {
    noBackground: {
      true: {
        backgroundColor: "transparent",
      },
    },
  },
});
