import { styled } from "~/ui/style/stitches.config";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import {
  useMutateRoomState,
  useMutateStorage,
  useStorage,
} from "~/state/liveblocks.config";
import { RoomState } from "~/types/room/types";
import { localState } from "~/state/state";

import { ActionMenuItem, ActionConfirmMenu } from "./ActionsMenu";
import { LiveList, shallow } from "@liveblocks/client";
import { SpreadMenu } from "./SpreadMenu";
import { AnimatePresence, motion } from "framer-motion";
import { ConfirmMenuLabel } from "./BottomBar";
import { HelpButtonSmall } from "../components/HelpButton";
import { DeckInfoBox } from "./DeckInfoBox";
import { BackgroundMenu } from "./BackgroundMenu";
import CardSearchMenu from "./CardSearchMenu";
import { TypingMenu } from "./TypingMenu";

type BarItem = {
  label: string | null;
  icon: string | JSX.Element;
  hotkey?: string;
  isVisible: () => boolean;
  isHighlighted?: () => boolean;
  onClick: () => void;
  isSelected: () => boolean;
  secondMenu?: JSX.Element;
  confirmMenuLabel?: ConfirmMenuLabel;
  disappearingNote?: JSX.Element;
};

export const DisappearingNote = ({
  message,
  isVisible,
}: {
  message: string;
  isVisible: boolean;
}) => {
  const theme = useStorage((root) => root.theme);

  return (
    <StyledDisappearingNote
      Theme={theme}
      style={{
        opacity: isVisible ? 1 : 0,
        pointerEvents: isVisible ? "auto" : "none",
      }}
    >
      {message}
    </StyledDisappearingNote>
  );
};

const StyledDisappearingNote = styled("div", {
  position: "absolute",
  right: "44px",
  width: "146px",
  backgroundColor: "$wash",
  color: "black",
  whiteSpace: "nowrap",
  fontSize: "12px",
  padding: "12px 8px",
  borderRadius: "12px",
  top: "-7px",
  zIndex: "5",
  bottom: 0,
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "opacity 0.3s ",
  variants: {
    Theme: {
      dark: {
        backgroundColor: "$darkwash",
        color: "white",
      },
      light: {},
    },
  },
});

export const ToolBarItem = ({
  barItem,
  confirmMenuOpen,
  bottomBar,
  changeDeckMenu,
  AV,
  narrow,
}: {
  barItem: BarItem;
  confirmMenuOpen: null | string;
  bottomBar?: boolean;
  changeDeckMenu?: boolean;
  AV?: boolean;
  narrow?: boolean;
}) => {
  const theme = useStorage((root) => root.theme);

  return barItem.isVisible() ? (
    <AnimatePresence>
      <StyledToolbarItem
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        Theme={theme}
        openConfirmMenu={confirmMenuOpen && barItem.isSelected() ? true : false}
        changeDeckMenu={changeDeckMenu ? true : false}
        bottomBar={bottomBar ? true : false}
        // selected={barItem.isSelected()}
        highlighted={
          barItem.isHighlighted ? barItem.isHighlighted() : barItem.isSelected()
        }
        AV={AV ? true : false}
        narrow={narrow ? true : false}
        hasLabel={barItem.label ? true : false}
      >
        <div className="icon-box-container">
          <div className="icon-box" onClick={barItem.onClick}>
            {typeof barItem.icon === "string" ? (
              <>
                <img className="icon" src={`/images/${barItem.icon}`} />
                {changeDeckMenu && <div className="deck-text">Deck</div>}
              </>
            ) : (
              barItem.icon
            )}
            <img className="point" src="/images/room/point.svg" />
          </div>
          <div className="label-container">
            <span className="label">{`${barItem.label}`} </span>
            {barItem.hotkey && <span className="hotkey">{barItem.hotkey}</span>}
          </div>
        </div>
        {(barItem.confirmMenuLabel
          ? confirmMenuOpen === barItem.confirmMenuLabel
          : confirmMenuOpen) &&
          barItem.isSelected() && <>{barItem.secondMenu}</>}
        {barItem.disappearingNote}
      </StyledToolbarItem>
    </AnimatePresence>
  ) : (
    <></>
  );
};

export const Toolbar = () => {
  const { inDrawingMode, inTypingMode, isOnMobile } = useSnapshot(localState);
  const reversesOn = useStorage((root) => root.reversesOn);
  const searchTerm = useStorage((root) => root.searchTerm);
  const theme = useStorage((root) => root.theme);
  const spread = useStorage((root) => root.spread);
  const state = useStorage((root) => root.state);
  const deck = useStorage((root) => root.deck, shallow);

  const [confirmMenuOpen, setConfirmMenuOpen] = useState<string | null>(null);
  const setRoomState = useMutateRoomState();
  const isCardSearchOpen = useStorage((root) => root.isCardSearchOpen);
  const updateStorage = useMutateStorage();

  useEffect(() => {
    if (inTypingMode) {
      setConfirmMenuOpen("Text");
      localState.inDrawingMode = false;
      localState.isFocusedTyping = false;
    } else {
      if (confirmMenuOpen === "Text") setConfirmMenuOpen(null);
    }
  }, [inTypingMode]);

  useEffect(() => {
    if (inDrawingMode) {
      localState.inTypingMode = false;
      localState.isFocusedTyping = false;
    }
  }, [inDrawingMode]);

  useEffect(() => {
    if (confirmMenuOpen !== "Text") localState.inTypingMode = false;
  }, [confirmMenuOpen]);

  const clearTable = () => {
    localState.lastViewedCard = null;
    // Remove cards from spread slots
    let newSpread = { ...spread };
    newSpread.slots.forEach((slot) => {
      slot.cards = null;
    });

    // Remove cards from local active spread
    localState.activeSpread.slots.forEach((slot) => {
      slot.cards = null;
    });

    updateStorage((storage) => {
      // Remove card from cards in spread
      storage.set("cards", new LiveList([]));
    });

    updateStorage((storage) => {
      // Re-set spread card slots
      storage.set("spread", newSpread);
    });
  };

  useEffect(() => {
    localState.inDarkMode = theme === "dark" ? true : false;
  }, [theme]);

  useEffect(() => {
    if (confirmMenuOpen === "Search") {
      updateStorage((storage) => {
        storage.set("isCardSearchOpen", true);
      });
    } else {
      updateStorage((storage) => {
        storage.set("isCardSearchOpen", false);
      });
    }
  }, [confirmMenuOpen]);

  useEffect(() => {
    if (isCardSearchOpen && confirmMenuOpen !== "Search")
      setConfirmMenuOpen("Search");
    else if (!isCardSearchOpen && confirmMenuOpen === "Search")
      setConfirmMenuOpen(null);
  }, [isCardSearchOpen]);

  const [reversesNoteVisible, setReversesNoteVisible] = useState(false);
  const [spreadNoteVisible, setSpreadNoteVisible] = useState(false);

  const topBarContent = [
    {
      label: "Pen",
      icon: "room/pen-tool.svg",
      hotkey: "P",
      isVisible: () => (isOnMobile ? false : true),
      isSelected: () => inDrawingMode,
      onClick: () => (localState.inDrawingMode = !inDrawingMode),
    },
    {
      label: "Text",
      icon: "room/type.svg",
      hotkey: "T",
      isVisible: () => true,
      isSelected: () => inTypingMode,
      onClick: () => {
        localState.inTypingMode = !inTypingMode;
      },
      secondMenu: (
        <div className="confirm-menu spread-menu">
          <TypingMenu
            isOpen={true}
            setIsOpen={(isOpen: boolean) => {
              localState.inTypingMode = isOpen;
            }}
          ></TypingMenu>
        </div>
      ),
    },
    {
      label: "Change background",
      icon: "room/theme-tool.svg",
      isVisible: () => true,
      isSelected: () => confirmMenuOpen === "Change background",
      onClick: () =>
        confirmMenuOpen === "Change background"
          ? setConfirmMenuOpen(null)
          : setConfirmMenuOpen("Change background"),
      secondMenu: (
        <div className="confirm-menu spread-menu">
          <BackgroundMenu
            isOpen={true}
            setIsOpen={setConfirmMenuOpen}
            onSpreadChanged={() => {}}
          ></BackgroundMenu>
        </div>
      ),
    },
    {
      label: "No reverses",
      icon: "room/reverse-tool.svg",
      hotkey: "R",
      isVisible: () => true,
      isSelected: () => !reversesOn,
      onClick: () => {
        updateStorage((storage) => {
          storage.set("reversesOn", !reversesOn);
        });
        setReversesNoteVisible(true);
        window.setTimeout(() => setReversesNoteVisible(false), 3000);
      },
      disappearingNote: (
        <DisappearingNote
          isVisible={reversesNoteVisible}
          message={reversesOn ? "Reverses are on ✓" : "Cards are upright ✓"}
        />
      ),
    },
  ];

  const bottomBarContent = [
    {
      label: "Change spread",
      icon: "room/spread-tool.svg",
      isVisible: () => true,
      isSelected: () => confirmMenuOpen === "New spread",
      onClick: () =>
        confirmMenuOpen === "New spread"
          ? setConfirmMenuOpen(null)
          : setConfirmMenuOpen("New spread"),
      secondMenu: (
        <div className="confirm-menu spread-menu">
          <SpreadMenu
            isOpen={true}
            setIsOpen={setConfirmMenuOpen}
            onSpreadChanged={() => {
              setSpreadNoteVisible(true);
              window.setTimeout(() => setSpreadNoteVisible(false), 3000);
            }}
          ></SpreadMenu>
        </div>
      ),
      disappearingNote: (
        <DisappearingNote
          isVisible={spreadNoteVisible}
          message={"Spread updated ✓"}
        />
      ),
    },
    {
      label: "Search cards",
      icon: "room/search.svg",
      hotkey: "S",
      isVisible: () => state === RoomState.Draw,
      isSelected: () => isCardSearchOpen,
      isHighlighted: () => searchTerm !== "",
      onClick: () => {
        if (confirmMenuOpen !== "Search") {
          setConfirmMenuOpen("Search");
        } else {
          setConfirmMenuOpen(null);
        }
      },
      secondMenu: (
        <div className="confirm-menu">
          <CardSearchMenu />
        </div>
      ),
    },

    {
      label: "Clear cards",
      icon: "room/clear-tool.svg",
      isVisible: () => state === RoomState.Draw,
      isSelected: () => confirmMenuOpen === "Clear cards",
      onClick: () =>
        confirmMenuOpen === "Clear cards"
          ? setConfirmMenuOpen(null)
          : setConfirmMenuOpen("Clear cards"),
      secondMenu: (
        <div className="confirm-menu">
          <ActionConfirmMenu
            theme={theme}
            confirmMessage={"Move all cards back to the deck?"}
            onConfirm={() => {
              setConfirmMenuOpen(null);
              clearTable();
            }}
            onBack={() => setConfirmMenuOpen(null)}
          ></ActionConfirmMenu>
        </div>
      ),
    },
    {
      label: "Restart room",
      icon: "room/reset-tool.svg",
      isVisible: () => state === RoomState.Draw,
      isSelected: () => confirmMenuOpen === "Reset table",
      onClick: () =>
        confirmMenuOpen === "Reset table"
          ? setConfirmMenuOpen(null)
          : setConfirmMenuOpen("Reset table"),
      secondMenu: (
        <div className="confirm-menu">
          <ActionConfirmMenu
            theme={theme}
            confirmMessage={"Clear all cards and reset the deck?"}
            onConfirm={() => {
              setConfirmMenuOpen(null);
              clearTable();
              setRoomState(RoomState.Ready);
            }}
            onBack={() => setConfirmMenuOpen(null)}
          ></ActionConfirmMenu>
        </div>
      ),
    },
  ];

  return (
    <StyledToolbars>
      <StyledToolbar Theme={theme}>
        {topBarContent.map((barItem, i) => (
          <ToolBarItem
            key={i}
            barItem={barItem}
            confirmMenuOpen={confirmMenuOpen}
          ></ToolBarItem>
        ))}
      </StyledToolbar>
      <StyledToolbar
        style={{
          transition: "max-height 1s",
          maxHeight: `${
            50 *
            bottomBarContent.filter((barItem) => barItem.isVisible()).length
          }px`,
        }}
        Theme={theme}
      >
        {bottomBarContent.map((barItem, i) => (
          <ToolBarItem
            key={i}
            barItem={barItem}
            confirmMenuOpen={confirmMenuOpen}
          ></ToolBarItem>
        ))}
      </StyledToolbar>
    </StyledToolbars>
  );
};

const StyledToolbarItem = styled(motion.div, {
  "& .confirm-menu": {
    position: "absolute",
    right: "50px",
    borderRadius: "14px",
    border: "1px solid $darkwashA500",
    backgroundColor: "$wash",
    width: "150px",
    whiteSpace: "normal",
    paddingTop: "20px",
    paddingLeft: "2px",
    paddingBottom: "3px",
    paddingRight: "2px",
    top: 0,
    zIndex: 9,

    "&.bottom-bar-confirm-menu": {
      zIndex: "9",
      right: 0,
      top: "-145px",
      "&.spread-menu": {
        right: "-80px",
        bottom: "50px",
        top: "auto",
        paddingTop: 0,
      },
    },
    "& .arrow-button": {
      position: "absolute",
      right: "-1px",
      top: "5px",
      transform: "rotate(180deg)",
    },
    "&.spread-menu": {
      paddingBottom: 0,
      paddingLeft: 0,

      "& #shuffle-deck": {
        backgroundColor: "transparent",
        border: "none",
        width: "150px",
      },
      "& .arrow-button": {
        right: "3px",
        top: "7px",
        padding: "3px 7px",
      },
    },
  },
  "& img.icon": {
    width: "20px !important",
    height: "20px !important",
    margin: "0 !important",
  },
  "& img.point": {
    position: "absolute",
    right: "44px",
    top: 0,
    bottom: 0,
    width: "10px",
    margin: "auto",
    zIndex: "5",
  },
  position: "relative",
  marginBottom: "4px",
  marginTop: "4px",
  "& .icon-box": {
    height: "28px",
    width: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      "@notmobile": { backgroundColor: "#EBEBEB" },
    },
  },
  "& .icon-box-container:hover": {
    "& .label-container, & img.point": {
      display: "block",
    },
  },
  "& .label-container, & img.point": {
    display: "none",
  },
  "& .label-container": {
    position: "absolute",
    right: "50px",
    fontSize: "11px",
    top: 0,
    bottom: 0,
    margin: "auto",
    alignItems: "center",
    padding: "5px 10px",
    height: "24px",
    whiteSpace: "nowrap",
    backgroundColor: "black",
    color: "white",
    borderRadius: "8px",
    zIndex: "4",
    "& .hotkey": {
      fontSize: "10px",
      backgroundColor: "$gray700",
      fontWeight: "300",
      padding: "2px 4px",
      borderRadius: "4px",
      "@mobile": {
        display: "none",
      },
    },
  },
  variants: {
    hasLabel: {
      false: {
        "& .label-container": {
          display: "none !important",
        },
        "& .point": {
          display: "none !important",
        },
      },
    },
    openConfirmMenu: {
      true: {
        "& .label-container": {
          display: "none !important",
        },
        "& .point": {
          display: "none !important",
        },
      },
      false: {},
    },
    changeDeckMenu: {
      true: {
        "& .deck-text": {
          fontSize: "12px",
          marginLeft: "4px",
        },
        "& .icon-box-container": {
          marginLeft: "60px",
        },
        "& .icon-box": {
          width: "80px !important",
          height: "33px !important",
          borderRadius: "8px",
          marginLeft: "-46px",
          "@mobile": {
            width: "36px !important",
          },
          "& .deck-text": {
            "@mobile": {
              display: "none",
            },
          },
        },
        "& .confirm-menu, & .bottom-bar-confirm-menu": {
          left: "12px",
          width: "181px",
          paddingTop: "30px !important",
        },
        "& .label-container": {
          width: "100px",
        },
        "& img.point": {
          left: "-80px !important",
        },
        "& #shuffle-deck": {
          padding: 0,
          marginTop: "0px",
        },
      },
      false: {},
    },
    bottomBar: {
      true: {
        "& .label-container": {
          top: "-83px",
          left: "-10px",
          right: 0,
          margin: "auto",
          whiteSpace: "nowrap",
          textAlign: "center",
        },
        "& .arrow-button": {
          transform: "rotate(-90deg) !important",
        },
        "& .icon-box-container": {
          width: "90px",
          marginRight: "-6px",
        },
        "& .icon-box": {
          backgroundColor: "white",
          width: "75px",
          height: "33px",
          border: "1px solid black",
          "& img": {
            width: "20px !important",
          },

          "& img.point": {
            width: "10px !important",
            top: "-60px",
            transform: "rotate(90deg)",
            left: 0,
            right: 0,
          },
        },
      },
      false: {},
    },
    AV: {
      true: {
        "& .label-container": {
          top: "-83px",
          left: "-10px",
          right: 0,
          margin: "auto",
          whiteSpace: "nowrap",
          textAlign: "center",
        },
        "& .arrow-button": {
          transform: "rotate(-90deg) !important",
        },
        "@mobile": {
          "& .icon-box-container": {
            padding: "2px",
          },
        },
        "& .icon-box-container": {},
        "& .icon-box": {
          "& img": {
            width: "20px !important",
            "@mobile": {
              width: "24px !important",
              height: "24px !important",
            },
          },
          "& img.point": {
            width: "10px !important",
            top: "-60px",
            transform: "rotate(90deg)",
            left: 0,
            right: 0,
          },
        },
      },
    },
    narrow: {
      true: {
        "& .icon-box": {
          width: "14px",
          padding: 0,
          "@mobile": {
            width: "28px",
            "& svg": {
              width: "20px !important",
              height: "20px !important",
            },
          },
        },
      },
    },
    highlighted: {
      true: {
        "& .icon-box": { backgroundColor: "#d4d4d4" },
      },
      false: {},
    },
    Theme: {
      dark: {
        "& .icon-box:hover": {
          "@notmobile": { backgroundColor: "#3d3d3d" },
        },
        "& img": {
          filter: "invert(1)",
        },
        "& .label-container": {
          backgroundColor: "white",
          color: "black",
          "& .hotkey": {
            backgroundColor: "$gray200",
          },
        },

        "& .confirm-menu": {
          border: "1px solid $washA500",
          backgroundColor: "$darkwash",
        },
      },
      light: {},
    },
  },

  compoundVariants: [
    {
      Theme: "dark",
      highlighted: true,
      css: {
        "& .icon-box": {
          backgroundColor: "#595959",
        },
      },
    },
    {
      Theme: "dark",
      bottomBar: true,
      css: {
        "& .icon-box": {
          backgroundColor: "black",
          width: "75px",
          border: "1px solid white",
        },
      },
    },
  ],
});

const StyledToolbar = styled(motion.div, {
  width: "40px",
  backgroundColor: "$wash",
  padding: "2px 8px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: "16px",
  border: "1px solid $darkwashA500",
  borderRadius: "12px",
  variants: {
    Theme: {
      dark: {
        backgroundColor: "$darkwash",
        border: "1px solid $washA500",
      },
      light: {},
    },
    noBorder: {
      true: {
        backgroundColor: "transparent",
        border: "none",
      },
    },
  },
});

const StyledToolbars = styled("div", {
  top: 0,
  bottom: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
  margin: "auto",
  height: "295px",
  right: "12px",
  position: "absolute",
});
