import { CheckIcon } from "@radix-ui/react-icons";
import { Button } from "../components/Button";
import { useMutateStorage, useStorage } from "../../state/liveblocks.config";
import { localState } from "~/state/state";

import { StyledLeftMenu } from "./StyledMenus";
import { keyframes, styled } from "~/ui/style/stitches.config";
import { LiveList } from "@liveblocks/client";
import { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { PlusBadge } from "./BackgroundMenu";

export function TypingMenu({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Function;
}) {
  const theme = useStorage((root) => root.theme);
  const texts = useStorage((root) => root.texts);
  const updateStorage = useMutateStorage();
  const [wasSmall, setWasSmall] = useState(false);

  const { isOnPlusPlan } = useSnapshot(localState);

  useEffect(() => {
    if (texts.length === 0) {
      setWasSmall(true);
    }
  }, [texts.length]);

  const textMenu = (
    <StyledLeftMenu id={"shuffle-deck"} Theme={theme}>
      <Button
        Theme={theme}
        role="menuitem"
        className="arrow-button"
        style={{}}
        transparent
        onClick={() => {
          setIsOpen(false);
        }}
      >
        &larr;
      </Button>

      <div
        className={`content `}
        style={{
          fontSize: "11px",
          transition: "height 0.2s ease-out",
          height: !isOnPlusPlan ? "90px" : texts.length > 0 ? "114px" : "54px",
        }}
      >
        <div
          style={{
            marginTop: "6px",
            padding: "0px 12px",
            position: "relative",
          }}
        >
          <PlusBadge
            clickable
            isOnPlusPlan={isOnPlusPlan}
            Theme={theme}
            style={{ position: "absolute", right: "12px", top: "0px" }}
          >
            PLUS
          </PlusBadge>
          <div
            style={{
              fontSize: "11px",
              marginBottom: "10px",
              paddingTop: "4px",
            }}
          >
            Create text{" "}
          </div>
          <div style={{ fontSize: "10px" }}>
            Tap anywhere in the room to create text.
          </div>
        </div>
        {!isOnPlusPlan && (
          <Button
            lightweight
            style={{
              width: "calc(100% - 24px)",
              marginLeft: "12px",
              marginTop: "12px",
            }}
            Theme={theme}
            onClick={() => {
              window.open("/plans", "_blank");
            }}
          >
            Enable
          </Button>
        )}
        {isOnPlusPlan && texts.length > 0 && (
          <StyledFadeIn wasSmall={wasSmall}>
            <div
              className="separator"
              style={{
                width: "100%",
                height: "1px",
                marginLeft: "-1px",
                backgroundColor: "rgba(127, 127, 127, 0.3)",
                marginTop: "12px",
                marginBottom: "14px",
              }}
            ></div>
            <StyledItemButton
              className="button"
              Theme={theme}
              style={{
                cursor: "pointer",
                margin: "0 18px",
                width: "calc(100% - 36px)",
                padding: "10px 0",
                textAlign: "center",
                borderRadius: "24px",
              }}
              onClick={() => {
                // clear all text boxes
                updateStorage((storage) => {
                  storage.set("texts", new LiveList([]));
                });
              }}
            >
              Clear all
            </StyledItemButton>
          </StyledFadeIn>
        )}
      </div>
    </StyledLeftMenu>
  );

  return <div>{textMenu}</div>;
}

const fadeIn = keyframes({
  "0%": { opacity: 0 },
  "50%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const StyledFadeIn = styled("div", {
  opacity: 1,
  variants: {
    wasSmall: {
      true: {
        animation: `${fadeIn} 0.5s ease-in-out`,
      },
      false: {
        animation: "none",
      },
    },
  },
});
const StyledItemButton = styled("div", {
  variants: {
    Theme: {
      dark: {
        backgroundColor: "black",
        border: "1px solid $washA500",
        "&:hover": {
          backgroundColor: "$gray700",
        },
      },
      light: {
        backgroundColor: "white",
        border: "1px solid $darkwashA500",
        "&:hover": {
          backgroundColor: "$gray100",
        },
      },
    },
  },
});
